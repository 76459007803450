import { createResourceService, filterAction, Resource, type PubSubMessage } from "@imminently/immi-query";
import { getAuthHook } from "@imminently/imminently_platform";
import { createAppResourceService, getCurrentUser } from "global";
import { useEffect, useState } from "react";
import { BehaviorSubject, timer } from "rxjs";
// import { filter } from "rxjs/operators";
import type { Document, Project, Release, Task, Workspace } from "@packages/commons";

export type Service = ReturnType<typeof createResourceService>;

export type AuthUser = Resource & {
  identity_id: string;
  first_name: string;
  last_name: string;
  email: string;
  roles: string[];
};

/** Re-export of getAuthHook for ease of use
 * 
 * @returns the user stored in redux
 */
export const useUser = () => {
  const { user } = getAuthHook();
  return user as AuthUser;
}

export type AuthKey = Resource & {
  name: string;
  tenancy: string;
  validUntil: number;
};

export type HelpArticle = Resource & {
  name: string;
  content: string;
};

export type AuthTenancy = Resource & {
  name: string;
  description: string;
};

export type FlowTask = Task & {
  outcome: any;
  justification: string;
  approvalLabel?: string;
  approvalOutcome?: string;
  rejectionLabel?: string;
  rejectionOutcome?: string;
};

export const workspaceService = createAppResourceService<Workspace>("workspaces");
export const projectService = createAppResourceService<Project>("models");
export const releaseService = createAppResourceService<Release>("releases");
export const tasksService = createAppResourceService<Task>("tasks");
export const flowTasksService = createAppResourceService<FlowTask>("tasks");
export const reportService = createAppResourceService<any>("decisionreports");
export const userService = createAppResourceService<AuthUser>("auth/users");
export const keyService = createAppResourceService<AuthKey>("auth/keys");
export const helpService = createAppResourceService<HelpArticle>("auth/help");
export const tenancyService = createAppResourceService<AuthTenancy>("auth/tenancies");
export const sessionService = createAppResourceService<any>("sessions");

// TODO the pubsub for this is trying to fetch the token before we have valid auth
// need a method to ensure auth exists before we query
// ideally we want this to work inside the library to avoid this being a common problem
export const documentService = createAppResourceService<Document>("documents");

type Reader = { id: string; resource: string; identity_id: string; full_name: string; time: number; };
// type DocumentMsg = PubSubMessage<Document>;
type ReaderMsg = PubSubMessage<Reader>;

// export const useDocumentTest = () => {
//   useEffect(() => {
//     // the encounter service pubsub can also emit other data types
//     const sub = documentService.pubsub$
//       // .pipe<DocumentMsg>(filterAction("nextobs"))
//       .subscribe((msg) => {
//         console.log("[Document Pubsub] received message", msg);
//       });
//     return () => {
//       console.log("[Document Pubsub] unsubscribing...");
//       sub.unsubscribe();
//     };
//   }, []);

//   return { };
// };

// if we use an app wide store, it's still not 100% accurate but would be better than nothing
// using rxjs as a store

type EditorMap = Map<string, Reader[]>;
const _documentEditors = new Map<string, Reader[]>();
const $editors = new BehaviorSubject<EditorMap>(_documentEditors);

// 30 second timeout
timer(0, 30000).subscribe(() => {
  // console.log("[Document Pubsub] clearing editors");
  const user = getCurrentUser();
  const isSelf = (e: Reader) => e.identity_id === user?.profile.oid;
  // _documentEditors.clear();
  _documentEditors.forEach((v, k) => {
    const editors = v.filter(e => isSelf(e) || (Date.now() - e.time) < 3000);
    _documentEditors.set(k, editors);
  });
  $editors.next(_documentEditors);
});

const addEditor = (documentId: string, editor: Reader) => {
  const editors = _documentEditors.get(documentId) || [];
  const index = editors.findIndex(e => e.identity_id === editor.identity_id);
  if (index !== -1) {
    // update the user time
    editors[index].time = Date.now();
    _documentEditors.set(documentId, editors);
    $editors.next(_documentEditors);
    return;
  }
  // debugger;
  editors.push(editor);
  _documentEditors.set(documentId, editors);
  $editors.next(_documentEditors);
};

export const DocumentEditors = () => {
  useEffect(() => {
    const user = getCurrentUser();
    if (!user) return;
    const sub = documentService.pubsub$
      .pipe<ReaderMsg>(filterAction("reader"))
      .subscribe((msg) => {
        // console.log("[Document Pubsub] new reader", msg);
        addEditor(msg.data.id, { ...msg.data, time: Date.now() });
      });

    return () => {
      // console.log("[DocumentEditors] unsubscribing...");
      sub.unsubscribe();
    };
  });
  return null;
};

// listens for document editor messages and updates the list of editors
// TODO how? the backend is stateless, so we need to store the list of editors somewhere
// how do we know when to remove an editor from the list?
export const useDocumentEditors = (documentId: string) => {
  // const [readers, actions] = useList<Reader>();
  const [editors, setEditors] = useState<Reader[]>([]);

  useEffect(() => {
    // the encounter service pubsub can also emit other data types
    const sub = $editors
      .subscribe((msg) => {
        // console.log("[Document Pubsub] updating editors", msg, documentId, msg.get(documentId));
        setEditors([...msg.get(documentId) ?? []]);
      });
    return () => {
      // console.log("[Document Pubsub] unsubscribing...");
      sub.unsubscribe();
    };
  }, [setEditors]);

  return editors;
};