import * as React from "react";
import { ChevronleftIcon, ChevronrightIcon } from "@icons";
import { DayPicker } from "react-day-picker";
import cn from "classnames";
import styled from "styled-components";
import styles from "./calendar.module.scss";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const CLASS_NAMES = {
  months: styles.months,
  month: styles.month,
  caption: styles.caption,
  caption_label: styles.caption_label,
  nav: styles.nav,
  nav_button: styles.nav_button,
  nav_button_previous: styles.nav_button_previous,
  nav_button_next: styles.nav_button_next,
  table: styles.table,
  head_row: styles.head_row,
  head_cell: styles.head_cell,
  row: styles.row,
  cell: styles.cell,
  button: styles.button,
  day: styles.day,
  day_range_end: styles.day_range_end,
  day_selected: styles.day_selected,
  day_today: styles.day_today,
  day_outside: styles.day_outside,
  day_disabled: styles.day_disabled,
  day_range_middle: styles.day_range_middle,
  day_hidden: styles.day_hidden,
};

const DatePicker = styled(DayPicker)`
  .DayPicker {
    width: 100%;
    border-radius: 0.5rem;
    background-color: var(--bg-primary);
    color: var(--text-primary);
  }

  .DayPicker-Month {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.25rem;
  }

  .DayPicker-Weekday {
    font-size: 0.75rem;
    color: var(--text-muted);
  }

  .DayPicker-Day {
    font-size: 0.75rem;
    color: var(--text-primary);
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
  }

  .DayPicker-Day--today {
    color: var(--text-accent);
    background-color: var(--bg-accent);
  }

  .DayPicker-Day--selected {
    color: var(--text-accent);
    background-color: var(--bg-accent);
  }

  .DayPicker-Day--disabled {
    color: var(--text-muted);
    background-color: var(--bg-muted);
  }

  .DayPicker-Day--outside {
    color: var(--text-muted);
    background-color: var(--bg-muted);
  }

  .DayPicker-NavButton {
    color: var(--text-primary);
  }

  .DayPicker-NavButton--prev {
    left: 1rem;
  }

  .DayPicker-NavButton--next {
    right: 1rem;
  }

  .DayPicker-Caption {
    font-size: 0.75rem;
    color: var(--text-muted);
  }
`;

export const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{ ...CLASS_NAMES, ...classNames }}
      components={{
        IconLeft: ({ ...props }) => <ChevronleftIcon className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronrightIcon className="h-4 w-4" />,
      }}
      {...props}
    />
  )
};