import { MentionCombobox } from "@udecode/plate";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { createMentionPluginFactory, filter, MentionItem, Item, getMentionOnSelectItem, comboboxStyles } from "./common";
import { Label } from "@components";

const RELATIONSHIP_TRIGGER_CHAR = "#";

const useRelationships = () => {
  const release = useFullRelease();

  return (release?.relationships ?? [])
    .reduce((items, relationship) => {
      if (!relationship.text || !relationship.containment) return items;
      const item = {
        key: relationship.text, // text should be unique
        text: (
          <>
            {relationship.name ? <Label color="purpleSlate">{relationship.name}</Label> : null}
            {relationship.text}
          </>
        ),
        value: relationship.text,
      } as MentionItem;
      items.push(item);
      return items;
    }, [] as MentionItem[]);
};

export const RelationshipCombobox = () => {
  const relationships = useRelationships();

  return (
    <MentionCombobox
      items={relationships}
      pluginKey={RELATIONSHIP_TRIGGER_CHAR}
      onRenderItem={Item}
      onSelectItem={getMentionOnSelectItem({ key: RELATIONSHIP_TRIGGER_CHAR })}
      filter={filter}
      styles={comboboxStyles}
    />
  );
};

export const createRelationshipsPlugin = createMentionPluginFactory({
  key: "relationships",
  trigger: RELATIONSHIP_TRIGGER_CHAR,
});
