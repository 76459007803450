import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { CloseIcon } from "@icons";
import dagre from "dagre";
import { DropdownMenu, Menu } from "@components";
import { Chip } from "@material-ui/core";
import { getContainedBy, getIndexForNode } from "../../../common";

const StyledDropDown = styled(DropdownMenu)`
  width: 100%;
`;

export interface GoalMenuProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  graph: any;
  onClick?: (id: string) => void;
  onGoalIdChange?: (id: string) => void;
  menuClassName?: string;
  allowEmpty?: boolean;
  /** a `placeholder` only makes sense if `allowEmpty` is `true` */
  placeholder?: string;
  goalId?: string;
  /** if `true` non instances (other than globals) will be ignored */
  instances?: boolean;
  disabled?: boolean;
};

const GoalMenu = (props: GoalMenuProps) => {
  const {
    graph,
    onClick,
    onGoalIdChange,
    menuClassName,
    allowEmpty = false, /* not sure why */
    goalId: goalIdFromProps,
    instances = false,
    ...otherProps
  } = props;

  const graphRef = React.useRef(graph);

  const [ stateGoalId, setStateGoalId ] = React.useState(goalIdFromProps);

  const currentGoalId = goalIdFromProps ?? stateGoalId;

  const handleGoalIdChange = (id) => {
    if (id !== stateGoalId) {
      setStateGoalId(id);
      if (onGoalIdChange) {
        onGoalIdChange(id);
      }
    }
  };

  const _graph = graph && (typeof graph.nodes === "function" ? graph : dagre.graphlib.json.read(graph)); // Depends if the graph has already been generated

  const getChipLabel = (node: any) => {

    let indexInfo: any = getIndexForNode(node, graph) || {};
    let nodeIndex = "";
    if (indexInfo) {
      nodeIndex = indexInfo.value;
    }
    let cleanIndex = (nodeIndex && nodeIndex.length > 8) ? `${nodeIndex.substring(0, 8)}...` : nodeIndex;
    let contained = getContainedBy(node.path, graph);
    const idLabel = indexInfo.hint ? indexInfo.hint : nodeIndex;
    const entityName = node.entity;

    // NOTE: globals will never have the `cleanIndex` populated, but it's fine...no need for special conditions
    const chipLabel = `${entityName}${cleanIndex ? `: ${ cleanIndex }` : ''}`;

    return (chipLabel);
  };

  const getGoal = (goals) => {
    if (goals.length === 0) return undefined;
    let n = goals.pop();

    let node = _graph.node(n);
    if (!node) {
      return getGoal(goals);
    }
    if (node.identifier || node.fk) node = getGoal(goals);
    return node;
  };

  const currentGoal = useMemo(() => {
    if (_graph) {
      const goal = _graph.node(currentGoalId);
      return goal ? goal.description : "";
    }
    return "";
  }, [ currentGoalId ]);

  useEffect(() => {

    if (graphRef.current !== graph) {
      graphRef.current = graph; // This used to be in the below code - but it caused additional goal resets.
    }

    if (_graph && !allowEmpty && currentGoalId === "") {
      const goals = _graph.sources();
      if (!goals || goals.length === 0) return;

      const goal = getGoal(goals);//_graph.node(goals[ 0 ]);
      if (goal) {
        const goalId = instances ? (goal.path || goal.id) : goal.id;
        handleGoalIdChange(goalId);
      }
    }
  }, [ currentGoalId, _graph, graph, allowEmpty ]);

  useEffect(() => {
    if (!goalIdFromProps) return;

    const goals = _graph.sources();
    if (!goals || goals.length === 0) return;


    /*
    // Why do we do this? This just unnecessarily resets the goal to the same id?
    const goal = _graph.node(goalIdFromProps);
    if (goal) {
      handleGoalIdChange(goal.id);
    }*/
  }, [ goalIdFromProps ]);


  const nodes: any[] = [];
  if (_graph) {
    _graph.nodes().forEach(n => {

      const node = _graph.node(n);
      if(!node) {
        return;
      }
      if (node.fk || node.identifier) {
        return;
      }

      // if (instances && !(node.path || node.entity === "global")) {
      //   return;
      // } else if (!instances && node.path) {
      //   return;
      // }
      // why over complicate it?...just check hidden
      if (instances && node.hidden) {
        return;
      }

      //if (_graph.successors(n).length === 0) return; // Only show goals and intermediate
      //console.log('n', node)

      const cfg = {
        id: node.id,
        name: node.description,
        action: <Chip
          style={{ background: "white" }}
          variant="outlined"
          size="small"
          // label={node.entity}
          label={getChipLabel(node)}
        />,
        isgoal: !((_graph.predecessors(n) || []).length > 0),
        path: node.path,
        entity: node.entity,
      };
      nodes.push(cfg);
    });
  }

  return (
    <div
      style={{
        display: "block",
        position: "relative",
      }}
    >
      {
        (currentGoalId && currentGoalId !== "" && allowEmpty)
        ?
          <div
            style={{
              position: "absolute",
              right: 35,
              top: "70%",
              transform: "translateY(-70%)",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "white",
            }}
            onClick={() => handleGoalIdChange("")}
          >
            <CloseIcon />
          </div>
        :
          null
      }
      <StyledDropDown
        showFullOnHover={true}
        value={currentGoal}
        {...otherProps}
      >
        {({ close }) => {
          const select = (g) => {
            const selectedId = instances ? (g.path || g.id) : g.id;
            handleGoalIdChange(selectedId);
            close();
          };
          return (
            <Menu
              onOptionClick={select}
              search="Search goals..."
              // @ts-ignore
              width={500}
              // @ts-ignore
              height={400}
              className={menuClassName}
              // @ts-ignore
              groupBy={[ {
                name: "Goal",
                filter: i => i.isgoal,
              }, {
                name: "Intermediate",
                filter: i => !i.isgoal,
              } ]}
              items={nodes}
            />
          );
        }}
      </StyledDropDown>
    </div>
  );
};

export default React.memo(GoalMenu);
