import { Typography } from "@material-ui/core";
import {
  type PlateEditor,
  TElement,
  type TNode,
  findNodePath,
  insertNodes,
  moveNodes,
  removeNodes,
  replaceNodeChildren,
  unwrapNodes,
  usePlateEditorState,
} from "@udecode/plate-core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { type BaseEditor, Editor, Path } from "slate";

import { useSection } from "@common/editor/components/section/SectionProvider";
import { ELEMENT_SECTION } from "@common/editor/components/section/sectionConstants";
import { createSection } from "@common/editor/components/section/sectionUtils";
import { Switch, TextField } from "@components";
import { ModalActions, ModalFields, type VoidFn, showConfirmation } from "@modals";
import { useTranslation } from "react-i18next";
import { HistoryEditor } from "slate-history";
import { EditorState } from "../../RuleAuthor/multiEditor";
import { getNodeById, getSelectedNode, wrapNodeRange } from "../../plugins/util";
import type { SectionElement } from "./section.types";
import { deriveSectionName } from "./sectionUtils";
import { SuggestSection, useOpenSuggestRulesModal } from "../suggest/SuggestRulesModal";
import { useCurrentRelease, useRelease } from "@common/hooks";

export const getParentSection = (editor, path?: Path) => {
  return Editor.above(editor as BaseEditor, { at: path, match: (n) => (n as TNode).type === ELEMENT_SECTION });
};

export const isWithinSection = (editor, path?: Path) => {
  const section = getParentSection(editor, path);
  return !!section;
};

type CreateSectionProps = {
  nodes: TNode[];
  close: VoidFn;
};

type EditSectionProps = {
  id: string;
  close: VoidFn;
};

export const useCreateSectionModal = () => {
  const dispatch = useDispatch();
  const source = usePlateEditorState(EditorState.source);
  const rules = usePlateEditorState(EditorState.rules);
  const { selectSection, addSection } = useSection();
  const { t } = useTranslation();
  const openSuggestRulesModal = useOpenSuggestRulesModal();

  const ModalBody = ({ nodes, close }: CreateSectionProps) => {
    const [name, setName] = useState(() => {
      return deriveSectionName(nodes);
    });
    const [generateRules, setGenerateRules] = useState(false);
    const release = useCurrentRelease();

    const onInsert = (section: SuggestSection, content: TElement[]) => {
      const existingSection = rules.children.find((e) => e.id === section.id);
      if (existingSection) {
        const path = findNodePath(rules, existingSection);
        if (path) {
          replaceNodeChildren(rules, { at: path, nodes: content });
        }
      } else {
        insertNodes(rules, createSection(section.id, content), {
          at: [rules.children.length],
        });
      }
    };

    const submit = () => {
      const parent = getParentSection(source);
      // make empty section so we can wrap existing nodes
      const section = createSection();

      // TODO need to make section creation into a transform that can be undone/redone
      // withoutNormalizing(source, () => {
      //   wrapNodeRange(source as BaseEditor, section);
      // });

      HistoryEditor.withoutSaving(source as HistoryEditor, () => {
        wrapNodeRange(source as BaseEditor, section);
      });

      // move new section out if required
      if (parent) {
        const [, parentPath] = parent;
        const [, sectionPath] = getNodeById(source as BaseEditor, section.id);
        moveNodes(source, { at: sectionPath, to: Path.next(parentPath) });
      }

      // copy content replace with generate rules https://app.clickup.com/t/86b23m7dv
      // copy into new section so we can add the new nodes
      // const content = copyContent ? nodesToTextNodes(nodes) : [];
      const content: any[] = [];
      const sect = { ...section, children: content } as SectionElement;
      HistoryEditor.withoutSaving(rules as HistoryEditor, () => {
        // default to end of document
        let insertPath = [rules.children.length];
        const [, sectionPath] = getNodeById(source as BaseEditor, section.id);
        // find the next section
        const nextSection = Editor.next<SectionElement>(source as BaseEditor, {
          at: sectionPath,
          match: (n) => (n as TNode).type === ELEMENT_SECTION,
        });
        if (nextSection) {
          // found next section in source, so find the one in rules and insert before it
          const [nextSourceSectionNode] = nextSection;
          const [, path] = getNodeById(rules as BaseEditor, nextSourceSectionNode.id);
          insertPath = path;
        }
        insertNodes(rules, sect, { at: insertPath });
      });
      // want to auto select section
      selectSection(sect.id);
      // add it to the section list
      addSection({ id: section.id, name, status: "" });
      // make sure we close the current modal first
      close();

      if (generateRules) {
        // open the auto generate rules modal
        openSuggestRulesModal({
          releaseId: release.id,
          model: release.model,
          onInsert,
          sections: [
            {
              id: section.id,
              name,
              status: "",
              source: { ...section, children: nodes as TElement[] },
            },
          ],
        });
      }
    };

    const submitOnEnter = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        // make sure we stop the event otherwise it will delete the highlighted text
        e.preventDefault();
        e.stopPropagation();
        submit();
      }
    };

    return (
      <>
        <ModalFields>
          <Typography>{t("build.section_create_description")}</Typography>
          <TextField
            fullWidth
            autoFocus
            onKeyDown={submitOnEnter}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Switch
            name="generate-rules"
            label={t("build.section_create_generate_rules")}
            checked={generateRules}
            onChange={(e) => setGenerateRules(e.target.checked)}
          />
          {/* <Checkbox checked={copyContent} onChange={(e) => setCopyContent(e.target.checked)} name="Copy content" /> */}
        </ModalFields>
        <ModalActions primary={{ name: t("create"), onClick: submit }} />
      </>
    );
  };

  return (nodes: TNode[]) =>
    dispatch(
      showConfirmation({
        title: t("build.create_section"),
        actions: null,
        body: (
          <ModalBody
            nodes={nodes}
            close={() => { }}
          />
        ),
      }),
    );
};

export const useRenameSectionModal = () => {
  const dispatch = useDispatch();
  const { setSection, sections } = useSection();
  const { t } = useTranslation();

  const ModalBody = ({ id, close }: EditSectionProps) => {
    const [name, setName] = useState(() => sections.find((s) => s.id === id)?.name ?? "");
    const submit = () => {
      setSection(id, { name });
      close();
    };

    const submitOnEnter = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        // make sure we stop the event otherwise it will delete the highlighted text
        e.preventDefault();
        e.stopPropagation();
        submit();
      }
    };

    return (
      <>
        <ModalFields>
          <Typography>{t("build.rename_section_description")}</Typography>
          <TextField
            fullWidth
            autoFocus
            onKeyDown={submitOnEnter}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </ModalFields>
        <ModalActions primary={{ name: t("documents.rename"), onClick: submit }} />
      </>
    );
  };

  return (id: string) =>
    dispatch(
      showConfirmation({
        title: t("build.rename_section_title"),
        actions: null,
        body: (
          <ModalBody
            id={id}
            close={() => { }}
          />
        ),
      }),
    );
};

const removeSectionNode = (editor: PlateEditor, id: string, unwrap = false) => {
  // find section using id
  const [, path] = getNodeById(editor as BaseEditor, id);
  // do not include in editor history
  HistoryEditor.withoutSaving(editor as HistoryEditor, () => {
    if (unwrap) {
      unwrapNodes(editor, { at: path });
      return;
    }
    removeNodes(editor, { at: path });
  });
};

export const useDeleteSectionModal = () => {
  const dispatch = useDispatch();
  const { removeSection } = useSection();
  const source = usePlateEditorState(EditorState.source);
  const rules = usePlateEditorState(EditorState.rules);
  const { t } = useTranslation();

  const ModalBody = ({ id, close }: EditSectionProps) => {
    const submit = () => {
      removeSection(id);
      removeSectionNode(source, id, true);
      removeSectionNode(rules, id);
      close();
    };

    return (
      <>
        <ModalFields>
          <Typography variant="h5">{t("are_you_sure")}</Typography>
          <Typography>{t("build.remove_section_warning")}</Typography>
          <Typography>{t("cannot_be_undone")}</Typography>
        </ModalFields>
        <ModalActions primary={{ name: "Delete", onClick: submit, error: true, variant: "contained" }} />
      </>
    );
  };

  return (id: string) =>
    dispatch(
      showConfirmation({
        title: t("build.delete_section"),
        actions: null,
        body: (
          <ModalBody
            id={id}
            close={() => { }}
          />
        ),
      }),
    );
};

const useSelectCurrentSection = (editor: PlateEditor) => {
  const { selectSection } = useSection();
  useEffect(() => {
    const [node, path] = getSelectedNode(editor as BaseEditor);
    if (!node && !path) return;
    // find section above
    const section = Editor.above(editor as BaseEditor, {
      at: path,
      match: (n) => (n as TNode).type === ELEMENT_SECTION,
    });
    if (!section) {
      // we are not within a section, so deselect
      selectSection(null);
      return;
    }
    // we have a section, select it
    selectSection((section[0] as SectionElement).id);
  }, [editor.selection]);
};

// TODO is there anther way to share data between editors?
// probably want to use the context or some event system
// has to exist below both plate providers, as it needs access to both editors
// actually if it was a plugin within rules, it could listen to an event or context
// source would have a plugin to emit the event

// TODO how do we deal with undo/redo with sections?
// right now its completely disabled
// could try seeing if we can add a custom action/callback to the history
// that could then do the required redux actions

export const SharedListener = () => {
  const source = usePlateEditorState(EditorState.source);
  const rules = usePlateEditorState(EditorState.rules);
  const showCreateSectionModal = useCreateSectionModal();
  useSelectCurrentSection(source);
  useSelectCurrentSection(rules);

  useEffect(() => {
    const index = (source.onCreateSection as any[]).push(showCreateSectionModal);

    return () => {
      (source.onCreateSection as any[]).splice(index - 1, 1);
    };
  }, [source, showCreateSectionModal]);

  return null;
};
