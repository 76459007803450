import { ELEMENT_CONCLUSION, ELEMENT_CONDITION } from "@common/editor/components/elements";
import { getRuleLevel, paragraphToRule } from "@common/editor/components/rule/ruleTransforms";
import { scrollableMixin } from "@common/scrollbar";
import Typography from "@material-ui/core/Typography";
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  comboboxActions,
  comboboxSelectors,
  MentionPlugin,
  TComboboxItemBase,
  createMentionPlugin,
  createPluginFactory,
  getEditorString,
  getPlugin,
  getPointBefore,
  getRange,
  isSelectionInMentionInput,
  withMention,
  ComboboxItemProps,
  NoData,
  TComboboxItem,
  getParentNode,
  ELEMENT_PARAGRAPH,
  PlateEditor,
  insertNodes,
} from "@udecode/plate";
import { Editor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import styled, { css } from "styled-components";

export const filter = (text: string) => (item: TComboboxItemBase) =>
  // try filter using text, otherwise fallback to key
  (typeof item.text === "string" ? item.text : item.key)
    .toLowerCase().indexOf(text.toLowerCase()) !== -1;

export type MentionItem = TComboboxItem<NoData>;

export const getMentionOnSelectItem = ({ key = ELEMENT_MENTION } = {}) =>
  (editor, item) => {
    const targetRange = comboboxSelectors.targetRange();
    if (!targetRange) return;

    const {
      options: { insertSpaceAfterMention, onInsert },
    } = getPlugin(editor, key);

    Editor.withoutNormalizing(editor, () => {
      Transforms.select(editor, targetRange);

      HistoryEditor.withoutMerging(editor, () => {
        Transforms.removeNodes(editor, {
          match: (node) => (node as any).type === ELEMENT_MENTION_INPUT,
        });
      });

      let _text = item.value ?? item.text;
      if (insertSpaceAfterMention) {
        _text += " ";
      }

      const defaultInsert = (e: PlateEditor, text: string) => {
        insertNodes(e, { text });
        // TODO - this was defined in the functions plugin override
        // but didn't like that it meant other plugins relied on that one.
        // Ideally since this references rules, it would not be defined here

        // if the parent (main node) is a paragraph, convert to rule
        const pNode = getParentNode(editor, editor.selection.anchor.path);
        if (!pNode) return; // no parent, we are done
        const [parent, parentPath] = pNode;
        if (parent.type === ELEMENT_PARAGRAPH) {
          // convert to rule with the fn text
          const level = getRuleLevel(editor, parentPath);
          paragraphToRule(editor, parentPath, level === 0 ? ELEMENT_CONCLUSION : ELEMENT_CONDITION);
        }
      };

      if (onInsert) {
        const res = onInsert(editor, _text);
        // user did a hard override
        if(res === true) return;
        // user changed the insert text
        if(typeof res === "string") {
          _text = res;
        }
        // flow through to default insert
      }

      defaultInsert(editor, _text);
    });
    return comboboxActions.reset();
  };

const ItemContainer = styled.div`
  padding: 0.25rem 0.5rem;
  span {
    text-wrap: wrap;
    margin-right: 0.25rem;
  }
  .MuiTypograhpy-root {
    font-weight: bold;
  }
`;

export const Item = ({ item }: ComboboxItemProps<NoData>) => {
  const { text } = item;
  return (
    <ItemContainer>
      <Typography variant="caption">
        {text}
      </Typography>
    </ItemContainer>
  );
};

const withCustomMention = (editor, plugin) => {
  // pass to original withMention
  const e = withMention(editor, plugin);
  const {
    options: { trigger, query, triggerPreviousCharPattern, inputCreation },
  } = plugin;
  const { insertText, insertNode } = e;
  const { type } = getPlugin(editor, ELEMENT_MENTION_INPUT);

  // override insertText with latest method
  // see https://github.com/udecode/plate/blob/main/packages/mention/src/withMention.ts
  e.insertText = (text) => {
    if (!editor.selection || text !== trigger || (query && !query(editor)) || isSelectionInMentionInput(editor)) {
      return insertText(text);
    }

    // Make sure a mention input is created at the beginning of line or after a whitespace
    const previousChar = getEditorString(
      editor,
      getRange(editor, editor.selection, getPointBefore(editor, editor.selection)),
    );
    const matchesPreviousCharPattern = triggerPreviousCharPattern?.test(previousChar);

    if (matchesPreviousCharPattern && text === trigger) {
      const data = {
        type,
        children: [{ text: "" }],
        trigger,
      };
      if (inputCreation) {
        data[inputCreation.key] = inputCreation.value;
      }
      return insertNode(data);
    }

    return insertText(text);
  };

  return e;
};

export const comboboxStyles = {
  root: [
    css`
      border-radius: 0.5rem;
      box-shadow: ${(p) => p.theme.body.boxShadow};
      width: 16rem;
      overflow: hidden;
      ${scrollableMixin};
    `,
  ],
};

export type MentionPluginOptions = {
  key: string;
  /** Character used to trigger the mention combobox */
  trigger: string;
  options?: MentionPlugin & Record<string, any>;
};

export const createMentionPluginFactory = ({ key, trigger, options = {} }: MentionPluginOptions) => {
  return createPluginFactory({
    key,
    plugins: [
      createMentionPlugin({
        key: trigger,
        withOverrides: withCustomMention,
        options: {
          trigger,
          // @ts-ignore
          triggerPreviousCharPattern: /^[\s(,]?$/,
          insertSpaceAfterMention: false,
          ...options,
        },
      }),
    ],
  });
};
