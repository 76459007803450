import {
  DefaultLeaf,
  type ENodeEntry,
  type Value,
  getParentNode,
  insertText,
  select,
  usePlateEditorRef,
  withoutSavingHistory,
} from "@udecode/plate";
import { type PropsWithChildren, type ReactElement, useRef, useState } from "react";

import { useGroupedGraph } from "@common/graph";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { scrollable } from "@common/scrollbar";
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger } from "@components/radix";
import { Typography } from "@material-ui/core";
import type {
  AttributeExpression,
  BaseExpression,
  CommandExpression,
  RelationshipExpression,
} from "@packages/compiler/src/Parser";
import { useCurrentDocument } from "@pages/documents";
import { useGraph } from "@pages/models/release/GraphContext";
import cn from "classnames";
import { camelCase, get, upperFirst } from "lodash";
import { Path, Text } from "slate";
import styled from "styled-components";
import { FunctionDeclaration } from "../../plugins/mentions";
import { ELEMENT_RULE } from "../elements";
import { EditorActiveContext } from "./EditorActiveContext";
import { AttributeInfo } from "./cards/AttributeCard";
import { RelationshipInfo } from "./cards/RelationshipCard";
import { parseExpressions, useSyntaxHighlight } from "./syntaxUtils";

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const SyntaxContent = styled(HoverCardContent)`
  /* min-width: 32rem; */
  max-width: 32rem;
  max-height: 15rem;
`;

type CustomLeafProps = {
  highlight: boolean;
  type: string;
  token: BaseExpression;
  depth: number;
  source: string;
};

const CommandInfo = ({ expression }: { expression: CommandExpression }) => {
  const { command } = expression;
  return (
    <Stack
      className={scrollable}
      style={{ padding: "0.5rem" }}
    >
      <Typography>
        <FunctionDeclaration data={command} />
      </Typography>
      {command.description ? (
        <Typography
          variant="caption"
          style={{ fontStyle: "italic" }}
          dangerouslySetInnerHTML={{ __html: command.description }}
        />
      ) : null}
    </Stack>
  );
};

const getConcludedAttributeErrors = (attributeId, document, getNode) => {
  const errors: any = [];
  if (!attributeId) return errors;

  const attribute = getNode(attributeId);
  if (!attribute) return errors; // This isn't technically an error - they are warned in the tooltip - we don't want the red squiggly line to appear
  /*if (attribute.definedIn && attribute.definedIn != (document.document?.name + '.docx')) {
    errors.push({
      message:`Warning there is already a derived attribute proven in ${attribute.definedIn.replace('.docx', '')}`
    });
  }*/
  return errors;
};

type SyntaxTooltipProps = PropsWithChildren & {
  open: boolean;
  setOpen: (o: boolean) => void;
  props: CustomLeafProps;
  parent: string;
  additionalErrors?: any[];
};

const SyntaxTooltip = ({ open, setOpen, props, parent, additionalErrors = [], children }: SyntaxTooltipProps) => {
  const { type, token } = props;

  let errors: ReactElement | null = null;
  if (token.errors?.length || additionalErrors?.length) {
    errors = (
      <Stack style={{ gap: 0, padding: "0.5rem", borderTop: "1px solid #e5e5e5" }}>
        <Typography
          variant="caption"
          style={{ fontWeight: "bold" }}
          color="error"
        >
          Errors:
        </Typography>
        {token.errors?.length
          ? token.errors.map((err, i) => (
            <Typography
              variant="caption"
              key={i}
            >
              {err.message}
            </Typography>
          ))
          : null}
        {additionalErrors?.length
          ? additionalErrors.map((err: any, i) => (
            <Typography
              variant="caption"
              key={i}
            >
              {err.message}
            </Typography>
          ))
          : null}
      </Stack>
    );
  }

  let body: ReactElement | null = null;
  switch (type) {
    case "command":
    case "command-fn":
      body = (
        <>
          <CommandInfo expression={token as CommandExpression} />
          {errors}
        </>
      );
      break;
    case "attribute":
      body = (
        <AttributeInfo
          expression={token as AttributeExpression}
          parent={parent}
        />
      );
      break;
    case "relationship":
      body = <RelationshipInfo expression={token as RelationshipExpression} />;
      break;
    default:
      // don't render anything for literals
      body = null;
  }

  // don't render any wrapper if there's no body
  if (body === null) return children;

  return (
    <HoverCard
      open={open}
      onOpenChange={setOpen}
    >
      <HoverCardTrigger>{children}</HoverCardTrigger>
      <HoverCardPortal>
        <SyntaxContent
          side="top"
          align="start"
        >
          {body}
        </SyntaxContent>
      </HoverCardPortal>
    </HoverCard>
  );
};

function memorySizeOf(obj) {
  let bytes = 0;

  function sizeOf(obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case "number":
          bytes += 8;
          break;
        case "string":
          bytes += obj.length * 2;
          break;
        case "boolean":
          bytes += 4;
          break;
        case "object": {
          const objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === "Object" || objClass === "Array") {
            for (const key in obj) {
              if (!Object.hasOwn(obj, key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
        }
      }
    }
    return bytes;
  }

  return sizeOf(obj);
}

export const useSyntaxHighlighter = (id: string) => {
  // const editor = usePlateEditorState(id);
  const { current: cache } = useRef({});
  const editor = usePlateEditorRef(id);
  const release = useFullReleaseStrict();
  const document = useCurrentDocument();
  const documentName = document ? `${document.name}.${document.type === "ruledoc" ? "docx" : "xlsl"}` : undefined;
  const syntax = useSyntaxHighlight(release, documentName);
  // const [highlight, setHighlight] = useState<string | null>(null);
  const graph = useGraph();
  const { getNode } = useGroupedGraph(graph);

  const decorate = (entry: ENodeEntry<Value>) => {
    const [node, path] = entry;
    const selection = editor.selection;
    const isSelectedNode = selection && Path.equals(selection.anchor.path, path);

    if (Text.isText(node)) {
      const [parent, parentPath] = getParentNode(editor, path) ?? [null, null];
      //console.log("PARENT", parent, parentPath);
      if (!parent || parent?.type !== ELEMENT_RULE) {
        if (isSelectedNode) {
          // setHighlight(null);
          editor.highlight = null;
        }
        return [];
      }

      let nodes: any[] = [];
      const useCache = false;
      // @ts-ignore
      if (useCache && cache[node.text]) {
        // @ts-ignore
        nodes = cache[node.text];
        // nodes = cached.nodes;
        // console.log('using cache', cached);
      } else {
        // time the parsing
        // const start = Date.now();

        try {
          const parseResult = syntax(node.text);
          if (parent.expression === "conclusion") {
            const firstExpression = parseResult.expressions[0];
            let concludedAttributeExpression: AttributeExpression | undefined;
            if (firstExpression?.type === "attribute") {
              concludedAttributeExpression = firstExpression;
            } else if (firstExpression?.type === "command" && firstExpression.command.id === "equals") {
              concludedAttributeExpression = firstExpression.arguments[0] as AttributeExpression;
            }
            if (concludedAttributeExpression?.attributeId) {
              const attributeErrors = getConcludedAttributeErrors(
                concludedAttributeExpression?.attributeId,
                document,
                getNode,
              );
              if (!concludedAttributeExpression.errors) {
                concludedAttributeExpression.errors = attributeErrors;
              } else {
                concludedAttributeExpression.errors.push(...attributeErrors);
              }
            }
          }

          // debugger;
          // @ts-ignore
          nodes = parseExpressions(node.text, parseResult.expressions).map((n) => ({
            ...n, // unpack og node (type, token, start, end, depth)
            anchor: { path, offset: n.start },
            focus: { path, offset: n.end },
            // type: n.type,
            // token: n.token,
            // depth: n.depth,
            source: node.text,
          }));

          // cache this on the parent node
          cache[node.text] = nodes;
        } catch (e) {
          if ((e as any).message) {
            console.warn("error parsing syntax", e as any, node, path);
            nodes = [
              {
                type: "error",
                token: { errors: [e] },
                start: 0,
                end: node.text.length,
                depth: 0,
                source: node.text,
                anchor: { path, offset: 0 },
                focus: { path, offset: node.text.length },
              },
            ];
          }
        }

        // log the time taken
        // const end = Date.now();
        // console.log('parsing time', node.text, end - start);

        // console the memory footprint of the cache
        // console.log('cache size', memorySizeOf(cache));

        // setNodes(editor, { cache: { tokens: { attributes: tokens.attributes, errors: tokens.errors, expressions: tokens.expressions }, nodes, parsed: node.text } }, { at: parentPath });
        // console.log('caching', cache[node.text]);

        // normalise command node text into pascal case
        const enableNormaliseCmd = false; // temp disable

        if (enableNormaliseCmd) {
          const normaliseCmdNodes = nodes
            .filter((n) => n.type.includes("command-fn"))
            .map((c) => ({
              ...c,
              normalised: upperFirst(camelCase(c.token.command.displayName)),
            }));

          for (const n of normaliseCmdNodes) {
            const start = n.start;
            const end = n.end;
            const text = node.text.substring(start, end);
            if (text !== n.normalised) {
              // convert to normalised text
              const range = {
                anchor: { path, offset: start },
                focus: { path, offset: end },
              };
              const ogSelect = editor.selection;
              withoutSavingHistory(editor, () => {
                insertText(editor, n.normalised, { at: range });
                // don't bother if we don't have a selection (ie first load)
                if (!ogSelect) return;
                // normalized text could be shorter than the original text, so use that as the focus
                // TODO sometimes when we insert we want it to be after the '(', need a way
                // to determine when and how we can do that
                const newFocus = { path, offset: start + n.normalised.length };
                const newSelect =
                  ogSelect.focus.offset < n.normalized.length
                    ? ogSelect // use original if we were inside the word
                    : { anchor: newFocus, focus: newFocus }; // else use new focus point
                // TODO for some reason we can't actually try/catch this??
                // make sure to put the cursor back/into the new position
                // debugger;
                select(editor, newSelect);
              });
            }
          }
        }
      }

      if (isSelectedNode) {
        if (!nodes || nodes.length === 0) {
          // reset highlight
          // setHighlight(null);
          editor.highlight = null;
        }
        // TODO looks achievable, just need to instead add selection as a node to the decoration
        // if selection is a range, highlight that text instead
        // const userSelection = window.getSelection();
        // // console.log('user selection', userSelection, selection, userSelection?.toString());
        // if (userSelection && userSelection.type === "Range") {
        //   const text = userSelection.toString();
        //   console.log("highlighting selection", text);
        //   setHighlight(text);
        // } else {
        const snode = nodes
          .filter((n) => !n.type.includes("command"))
          .find((n) => n.start <= selection.anchor.offset && selection.anchor.offset <= n.end);
        if (snode) {
          const text = node.text.substring(snode.start, snode.end);
          // console.log("highlighting attribute", text);
          // setHighlight(text);
          editor.highlight = text;
          // TODO how do i force a refresh here?
          // editor.apply({
          //   // @ts-ignore
          //   type: 'highlight',
          //   path: [],
          //   offset: 0,
          // })
        }
        // }
      }
      return nodes;
    }
    return [];
  };

  const renderLeaf = (props) => {
    const [open, setOpen] = useState(false);
    const highlight = editor.highlight;

    //console.log('LEAF', props, editor);
    const parent = get(props, "children.props.parent.expression");
    // This function calls hooks, so if we put it inside an if React
    // will complain that we call different hooks on renders
    //let attrErrors = getAttributeErrors(get(props, 'leaf.token.attributeId'), parent)

    if (props.leaf.token) {
      const token = props.leaf.token as BaseExpression;
      const classnames = cn(props.leaf.type, {
        error: token.errors?.length,
        highlight: highlight === props.leaf.text,
        tooltip: open,
      });

      const log = () => console.log(JSON.stringify(token, null, 2));
      // interactive
      return (
        <EditorActiveContext.Consumer>
          {(active) => (
            <SyntaxTooltip
              open={open}
              setOpen={setOpen}
              props={props.leaf}
              parent={parent}
            >
              <span
                {...props.attributes}
                className={classnames}
                onClick={log}
              >
                {props.children}
              </span>
            </SyntaxTooltip>
          )}
        </EditorActiveContext.Consumer>
      );
    }
    return <DefaultLeaf {...props} />;
  };

  return { decorate, renderLeaf };
};
