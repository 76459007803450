import { useScrollable } from "@common/hooks/useScrollable";
import { Flex, Label, Stack } from "@components";
import { Sort } from "@imminently/immi-query";
import { Tooltip, Typography } from "@material-ui/core";
import { Task } from "@packages/commons";
import { useScope } from "redux/scope";
import { FlowTask, flowTasksService } from "services";
import styled from "styled-components";
import { hexToHSL, system } from "theme";
import { StatusCircle } from "./utils";
import moment from "moment";
import { priorityColor, statusColor } from "@common/tasks/taskUtils";
import { AssigneeGroup } from "@components/forms/FormikControls";
import { useSessionTaskModal } from "./SessionTaskModal";
import { EmptyContent } from "@common";
import { useTranslation } from "react-i18next";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 0.5rem;
  height: calc(100% - 4rem);

  #header {
    gap: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1.5rem 1rem 1rem 1rem;
    
    border-bottom: 1px solid ${props => props.theme.palette.divider};

    >.MuiTypography-root {
      font-size: 1.125rem; // 18px
      font-weight: 600;
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  padding: 0 1rem;
  cursor: pointer;

  &[data-even="true"] {
    background-color: ${props => props.theme.palette.background.default};
  }
  &[data-even="false"] {
    background-color: ${props => props.theme.palette.background.light};
  }
  &:hover {
    background-color: ${props => props.theme.palette.background.hover};
  }
`;

//<Typography variant="caption">Unassigned</Typography>

const TaskListItem = ({ task, index }: { task: FlowTask; index: number; }) => {
  const { t } = useTranslation();
  const openTask = useSessionTaskModal();
  // date in format 24 June, 2024, using moment.js to format
  const dueDate = task.dueDate ? moment(task.dueDate).format("DD MMM, YYYY") : '';
  return (
    <ListItem data-even={index % 2 === 0} onClick={() => openTask(task)}>
      <Tooltip title={t(`tasks.status.${task.status}`)} placement="left">
        <StatusCircle color={hexToHSL(statusColor[task.status], 80)} />
      </Tooltip>
      <Stack flex={1}>
        <Typography>{task.title}</Typography>
        <Typography variant="caption">Session 1</Typography>
      </Stack>
      <AssigneeGroup users={task.assigneesData} emptyText={null} />
      <Typography variant="caption">{dueDate}</Typography>
      <Flex minWidth="4rem" justifyContent="flex-end">
        <Label color={hexToHSL(priorityColor[task.priority], 90)}>
          {t(`tasks.priority.${task.priority}`)}
        </Label>
      </Flex>
    </ListItem>
  );
};

export const TaskList = () => {
  const { workspace, project } = useScope();
  const { t } = useTranslation();
  const { data: tasks, total, query, loadMore } = flowTasksService.useInfiniteResources({
    perPage: 10,
    filter: {
      type: "flow",
      sort: { field: "created", order: "desc" } as Sort,
      status: ["notstarted", "inprogress", "not-started", "in-progress", "review", "blocked"],
      workspace,
      model: project,
    },
  });

  const { isLoading } = query;
  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);
  const scrollLoad = useScrollable(loadMore, canLoad, { autoScroll: true });

  return (
    <ListContainer>
      <Flex id="header">
        <Typography>{t('tasks.tasks')}</Typography>
        <Label color={system.orangeFiesta}>{total} {t('tasks.tasks').toLocaleLowerCase()}</Label>
      </Flex>
      {
        isLoading
          ? <EmptyContent messages={[t('loading_with_dots')]} img={null} />
          : tasks.length === 0
            ? <EmptyContent messages={[t('tasks.no_tasks')]} />
            : (
              <Stack {...scrollLoad}>
                {
                  tasks.length > 0
                    ? tasks.map((task, index) => (
                      <TaskListItem key={task.id} index={index} task={task as FlowTask} />
                    ))
                    : null
                }
              </Stack>
            )
      }
    </ListContainer>
  );
};