import { ReactNode } from "react";
import { useFormikContext } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextFieldProps } from "@material-ui/core/TextField";
import { formatUserFullName } from "@common/util";
import { Checkbox, Stack } from "@components";
import { Avatar, AvatarGroup } from "@components/avatar";
import ChipInput from "@components/chipInput";
import { Dropdown, TextField } from "@components/input";
import { Tooltip } from "@components/toolTip";
import { AssigneesData } from "@packages/commons";
import { FormHelperText, SelectProps, Typography } from "@material-ui/core";
import { get } from "lodash";
import { useParsedGraph } from "@pages/models/release/GraphContext";
import GoalMenu from "@pages/models/release/GoalMenu";
import { useTranslation } from "react-i18next";

export const FormikTextField = ({ name, label, helperText, ...props }: { name: string, label: string; helperText?: string; } & TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<any>();
  const value = get(values, name, "");
  const error = get(errors, name);
  const isTouched = get(touched, name);
  return (
    <TextField
      name={name}
      value={value}
      label={label}
      fullWidth
      helperText={(isTouched && (error as string)) ?? helperText}
      error={isTouched && Boolean(error)}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

type FormikSelectProps = {
  name: string;
  label: string;
  options: { value: string; key: string; }[];
} & SelectProps;

export const FormikSelect = ({ name, label, options, ...props }: FormikSelectProps) => {
  const { values, handleChange, errors } = useFormikContext<any>();
  const value = get(values, name, "");
  const error = get(errors, name);
  // const isTouched = get(touched, name);
  return (
    <Dropdown
      fullWidth
      name={name}
      label={label}
      value={value}
      items={options}
      onChange={handleChange}
      helperText={Boolean(error) ? error : undefined}
      {...props}
    />
  )
};

export const FormikCheckbox = ({ name, label }: { name: string; label: string; }) => {
  const { values, handleChange } = useFormikContext<any>();
  const value = get(values, name, false);
  // const error = get(errors, name);
  // const isTouched = get(touched, name);
  return (
    <FormControlLabel
      control={(
        <Checkbox
          name={name}
          checked={value}
          onChange={handleChange}
          style={{ marginRight: '1rem' }}
        />
      )}
      label={label}
      style={{ margin: 0 }}
    />
  );
};

export const FormikChipInput = ({ name, label }: { name: string; label: string; }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const value = get(values, name, []);
  // const error = get(errors, name);
  // const isTouched = get(touched, name);
  return (
    <ChipInput
      fullWidth
      // @ts-ignore it is defined. Why do you forsake me TS
      color="secondary"
      name={name}
      label={label}
      defaultValue={value}
      onChange={(tags) => setFieldValue(name, tags)}
      blurBehavior='add'
    />
  );
}

const assigneesTooltip = (assignees: AssigneesData[]) => (
  <Stack>
    {assignees.map((assignee) => <span key={assignee.id}>{assignee.name ?? formatUserFullName(assignee)}</span>)}
  </Stack>
);

export const AssigneeGroup = ({ users, max = 3, emptyText }: { users: AssigneesData[]; max?: number; emptyText?: ReactNode; }) => {
  const { t } = useTranslation();
  return users && users.length > 0 ?
    (
      <Tooltip title={assigneesTooltip(users)}>
        <AvatarGroup max={max} size="small">
          {
            users.map((user) => (
              <Avatar key={user.id} src={user.avatar ?? "/"} alt={user.firstName} size="small" />
            ))
          }
        </AvatarGroup>
      </Tooltip>
    )
    // use undefined check to allow null
    : emptyText === undefined ? t('users.no_members_assigned') : emptyText
};

// export const FormikAssignees = ({ name, label }: { name: string; label: string; }) => {
//   const { values, touched, errors, handleChange, handleBlur } = useFormikContext<any>();
//   const users = values[name] ?? [];
//   return (
//     <UserSelect value={values.users} onChange={handleChange}>
//       {

//       }
//     </UserSelect>
//   );
// };

/** Requires ParsedGraphContext */
export const FormikGoalSelect = ({ name, label }: { name: string; label: string; }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const graph = useParsedGraph();

  const value = get(values, name);
  const setValue = v => setFieldValue(name, v);
  const error = (touched[name] && errors[name]) || false;

  return (
    <Stack gridGap="0.5rem">
      <Typography variant="h6" aria-label={label}>{label}</Typography>
      <GoalMenu
        aria-labelledby={label}
        graph={graph}
        goalId={value}
        onGoalIdChange={setValue}
        allowEmpty
      />
      {
        Boolean(error) ? (
          <FormHelperText error style={{ margin: "0 1rem", fontStyle: "italic" }}>
            {error}
          </FormHelperText>
        ) : null
      }
    </Stack>
  );
}