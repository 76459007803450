// eslint-disable-next-line import/extensions
import countries from './countries.json';
// eslint-disable-next-line import/extensions
import languages from './languages.json';
// eslint-disable-next-line import/extensions
import currency from './currency.json';
import { Enum } from "../../../../../../../types/interfaces.rulegraph";


const typedCountries = countries as Array<{
  name: string;
  'alpha-2': string;
  'country-code':string
}>;
const typedLanguages = languages as Array<{ English: string; alpha2: string }>;
const typedCurrency = currency as Record< string, { name: string, symbol: { grapheme: string, }} >;
const currencyArr = (
  Object.keys(typedCurrency)
    .reduce< Array<{ code: string; v: typeof typedCurrency[ keyof typeof typedCurrency ] }> >(
      (a, it) => {
        const v = typedCurrency[ it ];
        if(v) {
          a.push({ code: it, v });
        }

        return a;
      },
      [],
    )
);

export type SystemEnum = Enum & { value: string };

const createReducer = (withTaiwan: boolean, twoDigitCode: boolean) => (
  (a: SystemEnum['options'], it: typeof typedCountries[ 0 ]): typeof a => {
    if(withTaiwan === false || it[ 'country-code' ] !== '158') {
      a.push({ label: it.name, value: twoDigitCode ? it[ 'alpha-2' ] : it[ 'name' ] });
    }

    return a;
  }
);


export const systemEnums: SystemEnum[] = [
  {
    value: 'system.countries',
    id: 'Countries (System)',
    type: 'text',
    options: typedCountries.reduce< SystemEnum['options'] >(
      createReducer(false, false),
      [],
    ),
  },
  {
    value: 'system.countries_taiwan',
    id: 'Countries - inc Taiwan (System)',
    type: 'text',
    options: typedCountries.reduce< SystemEnum['options'] >(
      createReducer(true, false),
      [],
    ),
  },
  {
    value: 'system.countries_code',
    id: 'Countries - 2 digit code (System)',
    type: 'text',
    options: typedCountries.reduce< SystemEnum['options'] >(
      createReducer(false, true),
      [],
    ),
  },
  {
    value: 'system.countries_code_taiwan',
    id: 'Countries - 2 digit code inc Taiwan (System)',
    type: 'text',
    options: typedCountries.reduce< SystemEnum['options'] >(
      createReducer(true, true),
      [],
    ),
  },
  {
    value: 'system.days_of_week',
    id: 'English days of the week (System)',
    type: 'text',
    options: [
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
      { label: 'Sunday', value: 'sunday' },
    ],
  },
  {
    value: 'system.languages_639',
    id: 'Languages - 2 digit code (System)',
    type: 'text',
    options: typedLanguages.map(it => ({ label: it.English, value: it.alpha2 })),
  },
  {
    value: 'system.currency',
    id: 'Currency - 3 digit code (System)',
    type: 'text',
    options: currencyArr.map(it => ({ label: it.v.name, value: it.code })),
  },
  {
    value: 'system.currency_symbol',
    id: 'Currency - Symbols (System)',
    type: 'text',
    options: currencyArr.map(it => ({ label: it.v.name, value: it.v.symbol.grapheme })),
  },
];
