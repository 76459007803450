import * as Yup from "yup";
import { useFullReleaseStrict, useUpdateFullRelease } from "@common/hooks_useFullRelease";
import { GraphNode, Trigger } from "@packages/commons";
import { useServiceForm } from "@modals";
import { releaseService } from "services";
import { useNotify } from "@common/notifications";
import { v4 as uuidv4 } from "uuid";

export const NONE_ENUM = "none";
export const ATTRIBUTE_TYPES = [
  { value: "boolean", key: "Boolean" },
  { value: "currency", key: "Currency" },
  { value: "date", key: "Date" },
  { value: "datetime", key: "Date Time" },
  { value: "number", key: "Number" },
  { value: "text", key: "Text" },
  { value: "timeofday", key: "Time" },
  { value: "auto", key: "Auto" },
];

export interface AttributeInfoFormValues {
  id: string;
  type: string;
  enum: string;
  publicId: string;
  category: boolean;
  // triggerEnrichments: boolean;
  tags: Array<string>;
  explanation: string;
  label: string;
  labelDisplay: string;
  triggers: Record<string, Trigger>;
}

const validationSchema = Yup.object().shape({
  enrichment: Yup.object()
    .when("triggerEnrichments", {
      is: true,
      then: schema => schema.shape({
        connection: Yup.object().shape({
          value: Yup.string().required("This field is required"),
        }),
      }),
    }),
});

const initValues = (node: GraphNode): AttributeInfoFormValues => {
  const triggers = node.triggers ?? [];
  // handle legacy values
  if (node.enrichment) {
    triggers.push(node.enrichment);
  }
  return {
    id: node.id,
    type: node.type,
    enum: node.enum || NONE_ENUM,
    publicId: node.publicId || "",
    tags: node.tags || [],
    category: node.category ?? false,
    // triggerEnrichments: Boolean(node.enrichment),
    explanation: node.explanation || "",
    label: node.controlDefaults?.label ?? "",
    labelDisplay: node.controlDefaults?.labelDisplay ?? "",
    // map triggers to object with uuids, need this so we can delete triggers
    triggers: Object.fromEntries(triggers.map((trigger) => [uuidv4(), trigger])),
  };
};

export const useAttributeForm = (node: GraphNode) => {
  const notify = useNotify();
  const release = useFullReleaseStrict();
  const updateRelease = useUpdateFullRelease();
  const service = releaseService.useSubresource("attribute", release.id);
  return useServiceForm({
    service,
    resource: node,
    options: {
      // no idea why the default is not working
      onError: (error: any) => {
        if (error.message) {
          notify.error(error.message, 5000);
        } else {
          notify.error("An error has occurred in the backend. Contact Support", 5000);
        }
      },
      onSuccess: () => {
        updateRelease();
      }
    },
    filter: ["type", "enum", "publicId", "triggers", "tags", "category", "explanation", "controlDefaults"],
    allowEmpty: false,
    formikValues: {
      initialValues: initValues(node),
      // enableReinitialize: true,
      validationSchema,
    },
    // validate: validate // TODO: check public name collision when we support that
    preSubmit: (values) => {
      // here we have raw form data, before any other transforms
      return {
        ...values,
        // map controlDefaults to the correct place, the filter will remove it from the root
        controlDefaults: {
          label: values.label,
          labelDisplay: values.labelDisplay,
        },
        // remove enum if it is none
        ...(values.enum === NONE_ENUM) ? { enum: null } : {},
        // remove enrichments if they are not enabled
        // ...(values.triggerEnrichments === false ? { enrichment: null } : {}),
        // always remove enrichment, as they are deprecated
        enrichment: undefined,
        // map triggers back to array
        triggers: Object.values(values.triggers),
      };
    },
  });
};
