import { useDispatch } from "react-redux";
import MomentUtils from "@date-io/moment";
import { DiscIcon } from '@radix-ui/react-icons';
import { FormProvider, useForm } from "react-hook-form";
import { Box, Breadcrumbs, IconButton, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FlagOutlined } from "@material-ui/icons";
import { Action, ModalActions, showModal } from "@modals";
import { scrollable } from "@common/scrollbar";
import { flowTasksService, FlowTask } from "services";
import { createFlowTask, FlowFormTask } from "@common/tasks/taskUtils";
import { AssigneeSelector, CheckboxInput, DateSelector, PrioritySelector, StatusSelector, TaskDetail, TextInput, TitleField } from "@common/tasks/FormControls";
import { CalendarIcon, CloseIcon, UsersIcon } from "@icons";
import { useFullScope } from "@common/hooks";
import { ProjectWriterGuard } from "auth/ComponentGuard";
import { useNotification } from "@common/notifications";
import styles from "./tasks.module.scss";
import clsx from "clsx";
import { Stack } from "@components";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import styled from "styled-components";
import { colors } from "theme";
import { useTranslation } from "react-i18next";

const PDFViewer = styled(DocViewer)`
  /* border: 1px solid red; */
  background-color: #a3a3a3 !important;
  border-radius: 0.5rem;

  #pdf-controls {
    display: none;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    background-color: ${p => p.theme.palette.background.default};

    button, a {
      box-shadow: none;
      border: none;
      &:hover {
        background-color: #f5f5f5;
        border-radius: 0.25rem;
      }
    }
  }

  .react-pdf__Document {
    padding: 2rem 0;
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }
`;

const FileViewer = ({ file }: { file?: File }) => {
  const docs = file ? [
    {
      uri: window.URL.createObjectURL(file),
      fileName: file.name,
    }
  ] : [{
    uri: "/National-Police-Check-NPC-Application-Form.pdf",
    fileName: "National-Police-Check-NPC-Application-Form.pdf",
  }];

  return (
    <PDFViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
      }}
      documents={docs}
      pluginRenderers={DocViewerRenderers}
    />
  );
};

const Placeholder = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  background-color: ${p => p.theme.palette.background.light};

  #information {
    grid-column: span 12 / span 12;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    #info-card {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      background-color: ${p => p.theme.palette.background.default};

      .MuiTypography-root {
        font-size: 0.875rem;

        &[aria-label] {
          color: ${p => p.theme.palette.background.darkBorder};
        }
      }
    }
  }

  #react-doc-viewer {
    grid-column: span 4 / span 4;
  }

  #interview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    grid-column: span 8 / span 8;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    background-color: ${p => p.theme.palette.background.default};

    .MuiFormControl-root {
      flex-direction: row;
      gap: 1rem;
    }
  }

`;

/** A placeholder component that will be replaced with a live interview SDK integration */
const InterviewPlaceholder = () => {
  return (
    <Placeholder>
      <Box id="information">
        <Typography variant="h6">Information</Typography>
        <Box id="info-card">
          <TaskDetail label="First names"><Typography>John Michael</Typography></TaskDetail>
          <TaskDetail label="Last name"><Typography>Smith</Typography></TaskDetail>
          <TaskDetail label="Passport number"><Typography>123456789</Typography></TaskDetail>
          <TaskDetail label="Date of birth"><Typography>12/12/1990</Typography></TaskDetail>
          <TaskDetail label="Street address"><Typography>1234 Street Avenue</Typography></TaskDetail>
          <TaskDetail label="City"><Typography>Sydney</Typography></TaskDetail>
          <TaskDetail label="Postal / Zip code"><Typography>1234</Typography></TaskDetail>
          <TaskDetail label="Country"><Typography>Australia</Typography></TaskDetail>
          <TaskDetail label="Email"><Typography>john.m.smith@email.com</Typography></TaskDetail>
          <TaskDetail label="Phone number"><Typography>+61 123 456 789</Typography></TaskDetail>
        </Box>
      </Box>
      <FileViewer />
      <Box id="interview">
        <Typography variant="h6" style={{ color: colors.reallyGrey }}>Interview</Typography>
        <Stack>
          <Typography><strong>Interview will be conducted here</strong></Typography>
          <Typography>
            The applicant has provided the following information.
            Please verify the information and conduct the interview.
          </Typography>
        </Stack>
        <Stack gridGap="0.5rem">
          <CheckboxInput name="verfied" label="I have verified the information" />
          <CheckboxInput name="processed" label="I have conducted the interview" />
        </Stack>
      </Box>
    </Placeholder>
  );
};

export const TaskModal = ({ task, close }: { task: FlowTask; close: () => void; }) => {
  const { workspace, project, release } = useFullScope();
  const notify = useNotification();
  const { t } = useTranslation();
  const { action, query } = flowTasksService.useMutateResource(task, {});

  const methods = useForm({
    defaultValues: createFlowTask(task)
  });

  const { handleSubmit } = methods;

  const approve = handleSubmit((data: FlowFormTask) => {
    const outcome = task.approvalOutcome ?? true;
    action({
      outcome,
      justification: data.justification,
    }).then(res => {
      flowTasksService.invalidateAllQueries();
      close();
    }).catch((e) => {
      notify.error(t('tasks.error_approve_msg'));
      console.error(e);
    });
  });

  const reject = handleSubmit((data: FlowFormTask) => {
    const outcome = task.rejectionOutcome ?? true;
    action({
      outcome,
      justification: data.justification,
    }).then(res => {
      flowTasksService.invalidateAllQueries();
      close();
    }).catch((e) => {
      notify.error(t('tasks.error_reject_msg'));
      console.error(e);
    });
  });

  const primaryAction: Action = {
    name: task.approvalLabel ?? t('tasks.approve_label'),
    onClick: approve,
    loading: query.isLoading,
    disabled: query.isLoading,
  };

  const secondaryAction: Action[] = [{
    name: task.rejectionLabel ?? t('tasks.reject_label'),
    onClick: reject,
  }];

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <FormProvider {...methods}>
          <Box className={clsx(styles.header, styles.borderBottom)}>
            <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrums}>
              {/* TODO this should actually be driven off the task if edit, scope if create */}
              <Box>{workspace?.name}</Box>
              <Box>{project?.name}</Box>
              <Box>Release {release?.releaseNo}</Box>
            </Breadcrumbs>
            <IconButton aria-label='close' onClick={close}>
              <CloseIcon />
            </IconButton>
          </Box>
          <div className={clsx(scrollable, styles.body)}>
            <Box className={clsx(styles.taskDetails, styles.container, styles.borderBottom)}>
              <TitleField />
              <Box className={styles.details}>
                <TaskDetail label={t('metadata.status')} icon={<DiscIcon width={24} height={24} />}>
                  <StatusSelector omit={["closed", "rejected", "approved"]} />
                </TaskDetail>
                <TaskDetail label={t('tasks.assignee')} icon={<UsersIcon />}>
                  <AssigneeSelector />
                </TaskDetail>
                <TaskDetail label={t('tasks.due_date')} icon={<CalendarIcon />}>
                  <DateSelector label="Pick a date" />
                </TaskDetail>
                <TaskDetail label={t('tasks.priority_label')} icon={<FlagOutlined />}>
                  <PrioritySelector />
                </TaskDetail>
              </Box>
            </Box>
            <InterviewPlaceholder />
            <Stack className={clsx(styles.taskDetails)}>
              <Typography variant="h6">{t('explanation.justification')}</Typography>
              <TextInput multiline minRows={4} name="justification" placeholder={t('tasks.justification_placeholder')} />
              <ProjectWriterGuard>
                <ModalActions className={styles.actions} primary={primaryAction} secondary={secondaryAction} />
              </ProjectWriterGuard>
            </Stack>
          </div>
        </FormProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export const useSessionTaskModal = () => {
  const dispatch = useDispatch();
  return (task: FlowTask) => {
    dispatch(
      showModal({
        open: true,
        title: null, // should give no title or close btn
        // TODO this wont work as we are hard overriding the header
        allowResize: true,
        maxWidth: 'lg',
        height: "80vh",
        style: { padding: 0 }
      },
        ({ close }) => <TaskModal task={task} close={close} />
      ));
  }
};