import React from "react";
import get from "lodash/get";
import { v4 as uuid } from 'uuid';
import debounce from "lodash/debounce";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import styled, { css } from "styled-components";
import { getEditorString, getPointBefore, getRange, MentionCombobox, TComboboxItem, useEditorState } from "@udecode/plate";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { createMentionPluginFactory, filter, getMentionOnSelectItem } from "./common";
import { Enum } from "@packages/commons";
import { Label } from "@components";
import { systemEnums } from "@pages/models/release/DataModel/route/Enumerations/SystemEnums/enums";
import { getSelectedNode } from "../util";
import { scrollableMixin } from "@common/scrollbar";


const comboboxStyles = {
  root: [
    css`
      border-radius: 0.5rem;
      box-shadow: ${(p) => p.theme.body.boxShadow};
      width: 16rem;
      height: 12rem;
      overflow: hidden;
    `,
  ],
};

const ENUMERATION_TRIGGER_CHAR = "{";

type EnumOption = {
  value: string;
  enumId: string;
  label: string;
  /** enum.id (which is more like enum label) + option label - for filtering */
  fullLabelLower: string;
  key: string;
};

const fallbackEnums: Enum[] = [];
const useEnumerations = (): EnumOption[] => {
  const release = useFullRelease();
  const enums: Enum[] = (release && release.enums) ? release.enums : fallbackEnums;

  return React.useMemo( () => enums.concat(systemEnums).reduce< EnumOption[] >(
    (acc, item) => {
      for (const option of item.options) {
        acc.push({
          value: option.value,
          enumId: item.id,
          label: option.label,
          fullLabelLower: `${ item.id } ${ option.label }`.toLowerCase(),
          key: uuid(),
        });
      }

      return acc;
    },
    []
  ), [ enums ] );
};


const onSelect = getMentionOnSelectItem({ key: ENUMERATION_TRIGGER_CHAR });

//# region ComboboxComponent

type ScrollLoadLimit = number;
const SCROLL_LOAD_BATCH_SIZE = 100;
/**
 * just a relatively small number of pixels to increase the gap where we\
 * consider current state as such that should trigger "load"
 */
const SCROLL_GAP = 20;

const ComboboxComponentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${ scrollableMixin };
  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

const ComboboxItem = styled(MenuItem)`
  flex-shrink: 0;
  display: block;
  padding: 0.125rem 1rem;
`;
const ComboboxItemEnumId = styled(Label)`
  white-space: wrap;
  line-height: 1.75rem;
`;

const ComboboxComponent = React.memo(() => {
  const items = useEnumerations();
  const editor = useEditorState();
  const selectedNode = getSelectedNode( editor );
  const maybeText = get(selectedNode, ['0', 'children', '0', 'text'], null);
  const wrapRef = React.useRef< HTMLDivElement >(null);

  const [ scrollLoadLimit, setScrollLoadLimit ] = React.useState< ScrollLoadLimit >(SCROLL_LOAD_BATCH_SIZE);
  const scrollLoadLimitRef = React.useRef(scrollLoadLimit);
  if(scrollLoadLimit !== scrollLoadLimitRef.current) scrollLoadLimitRef.current = scrollLoadLimit;

  //# region propagatableSearch

  const [propagatableSearch, setPropagatableSearch] = React.useState('');
  const setPropagatableSearchDebounced = React.useMemo(() => debounce(
    (v: string) => {
      setPropagatableSearch(v);
      setScrollLoadLimit(SCROLL_LOAD_BATCH_SIZE);

      const { current } = wrapRef;
      if(current === null) return;

      current.scrollTo({ top: 0, behavior: 'instant' });
    },
    700
  ), [setPropagatableSearch, setScrollLoadLimit, wrapRef]);

  React.useEffect(() => {
    if(typeof maybeText !== 'string') return;

    setPropagatableSearchDebounced(maybeText);
  }, [ maybeText, setPropagatableSearchDebounced ]);

  //# endregion


  const filtered = React.useMemo< EnumOption[] >(() => {
    if(propagatableSearch === '') return items;

    const searchLower = propagatableSearch.toLowerCase();

    return items.filter(it => it.fullLabelLower.indexOf(searchLower) !== -1);
  }, [items, propagatableSearch]);

  const filteredRef = React.useRef(filtered);
  if(filteredRef.current !== filtered) filteredRef.current = filtered;


  const filteredAndSliced = React.useMemo< typeof filtered >(
    () => filtered.slice(0, scrollLoadLimit),
    [filtered, scrollLoadLimit]
  );

  //# region "Load"MoreOnScrol

  const loadMoreOnScroll = React.useCallback( ( el: HTMLDivElement ) => {
    const { scrollTop, clientHeight, scrollHeight } = el;
    if(scrollHeight > scrollTop + clientHeight + SCROLL_GAP) return;

    const curScrollLoadLimit = scrollLoadLimitRef.current;
    const filteredLength = filteredRef.current.length;
    if(curScrollLoadLimit >= filteredLength) return;

    setScrollLoadLimit(prev => prev + SCROLL_LOAD_BATCH_SIZE);
  }, [scrollLoadLimitRef, filteredRef, setScrollLoadLimit]);

  const loadMoreOnScrollDebounced = React.useMemo(
    () => debounce(loadMoreOnScroll, 500),
    [loadMoreOnScroll]
  );

  const onScrollHandler = React.useCallback< React.UIEventHandler<HTMLDivElement> >(
    e => loadMoreOnScrollDebounced(e.currentTarget),
    [loadMoreOnScrollDebounced]
  );

  //# endregion


  const jsx = React.useMemo(() => (
    <ComboboxComponentWrap onScroll={onScrollHandler} ref={wrapRef}>
      {filteredAndSliced.map(it => (
        <ComboboxItem
          key={it.key}
          // onClick={() => console.log('click 2')}
          // onMouseUp={() => console.log('mouseup 2')}
          onMouseDown={() => {
            const comboboxItem: TComboboxItem = {
              key: it.value,
              text: it.value,
            };

            onSelect(editor, comboboxItem);
          }}
        >
          <ComboboxItemEnumId color='purpleSlate' >
            { it.enumId }
          </ComboboxItemEnumId>

          { ' ' }

          <Typography variant='caption' style={{ whiteSpace: 'wrap' }}>
            { it.label }
          </Typography>
        </ComboboxItem>
      ))}
    </ComboboxComponentWrap>
  ), [filteredAndSliced, onScrollHandler, wrapRef]);

  return jsx;
});
ComboboxComponent.displayName = 'plugins/mentions/enumerations/ComboboxComponent';

//# endregion


export const EnumerationCombobox = () => {
  return (
    <MentionCombobox
      pluginKey={ENUMERATION_TRIGGER_CHAR}
      filter={filter}
      styles={comboboxStyles}
      component={() => <ComboboxComponent />}
      disabled={false}
    />
  );
};

const QUOTE_CHARS = ["\"", "'"];

export const createEnumerationsPlugin = createMentionPluginFactory({
  key: "enumerations",
  trigger: ENUMERATION_TRIGGER_CHAR,
  options: {
    triggerPreviousCharPattern: /^[\s(,"']?$/,
    onInsert: (editor, text) => {
      const previousChar = getEditorString(
        editor,
        getRange(editor, editor.selection, getPointBefore(editor, editor.selection)),
      );
      const char = QUOTE_CHARS.find((char) => char === previousChar);
      if (char) {
        // wrap in quotes
        return text + char;
      }
    }
  }
});
