import { Label } from "@components";
import { FlagIcon } from "@icons";
import { priorityColor, statusColor } from "./taskUtils";
import styles from "./tasks.module.scss";
import { TaskPriority, TaskStatus } from "@packages/commons";
import { useTranslation } from "react-i18next";

// allowing generic string as TS is too annoying to bother with this

export const StatusLabel = ({ status }: { status: TaskStatus }) => {
  const { t } = useTranslation();
  return (
    <Label className={styles.label} color={statusColor[status]}>
      {t(`tasks.status.${status}`)}
    </Label>
  );
};

export const PriorityLabel = ({ priority }: { priority: TaskPriority }) => {
  const { t } = useTranslation();
  return (
    <>
      <FlagIcon style={{ color: priorityColor[priority] }} />
      <span>{t(`tasks.priority.${priority}`)}</span>
    </>
  );
};