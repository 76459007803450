import { useFormikContext } from "formik";
import { FormikCheckbox, FormikGoalSelect, FormikSelect, FormikTextField } from "@components/forms/FormikControls";
import { Stack } from "@components";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { AttributeInfoFormValues } from "./useAttributeForm";
import { get } from "lodash";

export const AttributeTaskForm = ({ scope }: { scope: string; }) => {
  const scopedValue = (name: string) => `${scope}.${name}`;
  const release = useFullReleaseStrict();
  const { values } = useFormikContext<AttributeInfoFormValues>();

  const interviews = release.interviews.map(it => ({ key: it.name, value: it.id }));
  const useAI = get(values, scopedValue("aiFirstPass"), false);

  return (
    <Stack gridGap="1rem">
      <FormikTextField fullWidth label="Title" name={scopedValue("title")} />
      <FormikGoalSelect label="Outcome" name={scopedValue("outcome")} />
      <FormikSelect label="Interview/Form" name={scopedValue("interview")} options={interviews} />
      <FormikGoalSelect label="Assignment" name={scopedValue("assignment")} />
      <FormikGoalSelect label="Due date" name={scopedValue("dueDate")} />
      <FormikTextField fullWidth label="Approval label" name={scopedValue("approvalLabel")} />
      <FormikTextField fullWidth label="Approval outcome" name={scopedValue("approvalOutcome")} />
      <FormikTextField fullWidth label="Rejection label" name={scopedValue("rejectionLabel")} />
      <FormikTextField fullWidth label="Rejection outcome" name={scopedValue("rejectionOutcome")} />
      <FormikCheckbox label="AI First Pass" name={scopedValue("aiFirstPass")} />
      {
        useAI ? (
          <FormikTextField fullWidth multiline minRows={4} label="Guidance" placeholder="AI guidance prompt..." name={scopedValue("guidance")} />
        ) : null
      }
    </Stack>
  );
};
