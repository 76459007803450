import { FormikGoalSelect, FormikSelect, FormikTextField } from "@components/forms/FormikControls";
import { Stack } from "@components";

export const AttributeCommunicationForm = ({ scope }: { scope: string; }) => {
  const methodOptions = [
    {
      value: "email",
      key: "Email",
    },
    {
      value: "sms",
      key: "SMS",
    }
  ];

  const scopedValue = (name: string) => `${scope}.${name}`;

  return (
    <Stack gridGap="1rem">
      <FormikTextField fullWidth label="Title" name={scopedValue("title")} />
      <FormikSelect label="Method" name={scopedValue("method")} options={methodOptions} />
      <FormikGoalSelect label="Who" name={scopedValue("who")} />
      <FormikTextField fullWidth label="Subject" name={scopedValue("subject")} />
      <FormikTextField fullWidth multiline minRows={4} label="Body" name={scopedValue("body")} />
    </Stack>
  );
};
