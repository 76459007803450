import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { ReleaseTestCasesNS } from '@packages/commons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx'
import produce from "immer";
import cloneDeep from 'lodash/cloneDeep';
import * as Yup from "yup";
import SortableTree, { NodeRenderer, type ReactSortableTreeProps } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {
  FolderIcon,
  TestingIcon,
  ChevrondownIcon,
  ChevronupIcon,
  MenudotsIcon,
} from "@icons";
import { TestCaseSortTItemMeta } from '../types';
import { useTestCasesRootCtx } from "../RootCtx";
import { Menu as MenuC } from "@components/menu";
import { withMenu } from "@components/input";
import { toggleExpanded, setExpandedOnAllInTestCasesSortTree } from "../utils";
import { scrollableMixin, useShowError } from "@common";
import { useOpenSaveTestCaseAsModal } from "./SaveAsModalContents";
import * as contextMenuNS from '../../../redux/contextMenu'
import { SingleFieldForm, SingleFieldFormProps } from "./SingleFieldForm";
import identity from "lodash/identity";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { LinkButton } from "@components/buttons";


//# region Menu

const TypedMenu = styled( MenuC )`
  width: 6.5rem;
`;

const Menu: React.FC< { items: Array<{ name: string, onClick: () => unknown }> } > = (
  React.memo(({ items }) => <TypedMenu small items={items} />)
);
Menu.displayName = "components/TestCasesTree/NodeContentRenderer/Menu";

//# endregion

const MenudotsIconKindaBlack = styled( MenudotsIcon )`
  color: ${ p => p.theme.palette.core.kindaBlack };
`;
const MoreMenu = ( withMenu as any )( MenudotsIconKindaBlack, { style: { zIndex: 1301 } } );


//# region NodeContentRenderer

const NodeContentWrap = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  align-items: center;
  color: ${ props => props.theme.palette.core.ultimateGrey };
  cursor: pointer;

  &.highlighted {
    background-color: ${ props => props.theme.palette.core.prayingMantis10 };
  }

  &.inactiveLeafNodes {
    color: ${ props => props.theme.palette.core.reallyGrey };
  }

  .moreMenu { display: none; }
  &:hover .moreMenu { display: block; }
`;
const NodeContentTitle = styled( Typography )`
  flex-grow: 1;
`;

const NodeContentIconButton = styled( IconButton )`
  width: 1.5rem;
  height: 1.5rem;
`;

type Renderer = ReactSortableTreeProps< TestCaseSortTItemMeta >[ 'nodeContentRenderer' ];
const NodeContentRenderer: Renderer = React.memo(p => {
  const dispatch = useDispatch();
  const { node: { __testCaseTreeItemId, expanded } } = p;
  const {
    value: {
      tree,
      selectedId,
      testCasesTreeCompState: {
        highlightedId,
        mode,
        isOpenedInCtxMenu,
      }
    },
    setValue,
    valueRef,
  } = useTestCasesRootCtx();
  const fullRelease = useFullRelease();
  const releaseId = fullRelease && fullRelease.id;
  const showError = useShowError();

  const openSaveAsModalRaw = useOpenSaveTestCaseAsModal();
  const closeReduxContextMenu = React.useCallback(() => (
    dispatch(contextMenuNS.aCreators.requestToHide())
  ), [ dispatch ]);
  const openSaveAsModal: typeof openSaveAsModalRaw = React.useCallback((arg) => {
    if( isOpenedInCtxMenu) closeReduxContextMenu();

    openSaveAsModalRaw(arg);
  }, [ closeReduxContextMenu, isOpenedInCtxMenu ]);


  const dataNode = React.useMemo(() => (
    ReleaseTestCasesNS.utilsNS.tCasesFindById({ rootItem: tree, id: __testCaseTreeItemId })
    // __testCaseTreeItemId === rootTestCaseTItemId ? rootTestCaseTItem : (
    //   predicateFind({
    //     rootTreeItem: tree,
    //     predicate: it => it.id === __testCaseTreeItemId
    //   })
    // )
  ), [__testCaseTreeItemId, tree]);


  const toggleExpandedRaw = React.useCallback(() => {
    setValue( prev => produce(prev, draft => {
      const prevSortTreeState = draft.testCasesTreeCompState.tree;

      draft.testCasesTreeCompState.tree = toggleExpanded({
        sortTreeRoot: prevSortTreeState,
        dataTreeRoot: draft.tree,
        id: __testCaseTreeItemId,
      });
    }) );
  }, [ setValue, __testCaseTreeItemId ]);

  const toggleExpandedHandler = React.useCallback< React.MouseEventHandler< HTMLButtonElement > >( e => {
    e.stopPropagation();

    toggleExpandedRaw();
  }, [ toggleExpandedRaw ]);


  const onTreeNodeClick = React.useCallback(
    () => {
      if(dataNode === null) return;

      if(mode === 'folderSelect') {
        if(dataNode.type === 'folder') {
          setValue(prev => produce(prev, draft => {
            draft.testCasesTreeCompState.highlightedId = __testCaseTreeItemId;
          }));
        }

        return;
      }

      if(dataNode.type === 'folder') {
        toggleExpandedRaw();

        return;
      }

      (async () => {
        const { data, goal } = await valueRef.current.actions.loadTestCaseLeafPayload({
          releaseId,
          testCaseItemId: __testCaseTreeItemId,
        });

        setValue(prev => produce(prev, draft => {
          const maybeNode = ReleaseTestCasesNS.utilsNS.tCasesFindById({
            rootItem: draft.tree,
            id: __testCaseTreeItemId ,
          });
          if(maybeNode === null || maybeNode.type === 'folder') return;

          draft.selectedId = __testCaseTreeItemId;
          draft.goalId = goal;
          maybeNode.goal = goal;
          maybeNode.data = data;

          draft.actions.setDebuggerPayload(cloneDeep(data));
          draft.actions.setDebuggerGoal(goal);
          /**
           * if we are in "leafSelect" mode and clicked on leaf - most\
           * probably we want to open that payload. And so we need close\
           * reduxContextMenu so that we can see new payload right away
           */
          closeReduxContextMenu();
        }));

        return;
      })();
    }, [
      dataNode,
      mode,
      setValue,
      closeReduxContextMenu,
      valueRef,
      releaseId,
      __testCaseTreeItemId,
      toggleExpandedRaw,
    ]
  );

  const className = clsx([
    highlightedId === __testCaseTreeItemId && 'highlighted',
    mode === 'leafSelect' && selectedId === __testCaseTreeItemId && 'highlighted',
    mode === 'folderSelect' && dataNode && dataNode.type === 'payload' && 'inactiveLeafNodes',
  ]);

  type MenuItems = React.ComponentProps< typeof MenuC >[ 'items' ];
  const contextMenuItems = React.useMemo< MenuItems >( () => {
    const maybeParent = ReleaseTestCasesNS.utilsNS.tCasesFindParent({
      root: valueRef.current.tree,
      id: __testCaseTreeItemId
    });

    if(dataNode === null) return [];

    const getSchemaForSingleFieldModal = (
      parentId: ReleaseTestCasesNS.TestCaseTItem['id']
    ): SingleFieldFormProps[ 'schema' ] => (
      ( Yup.string() as Yup.StringSchema< string >).required( 'Required' )
        .test({
          name: 'uniqNewName',
          message: 'Duplicated name',
          test: v => {
            if(v === undefined || v === '' ) return false;

            return ReleaseTestCasesNS.utilsNS.tCasesAssertChildTitleUniq({
              root: valueRef.current.tree,
              parentId,
              title: v,
            });
          }
        })
    );

    const onRenameClick = (() => {
      if(maybeParent === null) return identity;

      const onRenameSubmit: SingleFieldFormProps[ 'onSubmit' ] = async name => (
        valueRef.current.actions.renameItem({
          newName: name,
          releaseId,
          testCaseItemId: __testCaseTreeItemId,
          setValue,
          treeItemRoot: valueRef.current.tree,
        })
      );

      return () => setValue(prev => produce(prev, draft => {
        draft.testCasesTreeCompState.dialogContents = <SingleFieldForm
          title="Rename"
          initialValue={dataNode.title}
          placeholder="Enter new name"
          schema={ getSchemaForSingleFieldModal(maybeParent.id) }
          onSubmit={ onRenameSubmit }
        />;
      }));
    })();

    /**
     * if we are currently in "folder select" mode, which happens when\
     * we are creating new test case - we only need to show "add folder"\
     * option and only for folders. No options for test cases (as those\
     * make no sense, we are already creating new test case) and no \
     * ability to alter folders in any other way exacept for "create new"\
     * (as this is very common when we want to create something and be\
     * able to provide proper nesting for it in the same dialog, like \
     * different OS-s do).\
     */
    if(mode === 'folderSelect') {
      if( dataNode.type === 'payload' ) return [];

      const onAddFolderSubmit: SingleFieldFormProps[ 'onSubmit' ] = async name => (
        valueRef.current.actions.createItem({
          idToAddTo: __testCaseTreeItemId,
          newTreeItem: {
            type: 'folder',
            id: uuid(),
            children: [],
            title: name
          },
          releaseId,
          setValue,
          treeItemRoot: valueRef.current.tree,
          type: 'singleFieldModal',
        })
      );

      const onClick = () => setValue(prev => produce(prev, draft => {
        draft.testCasesTreeCompState.dialogContents = <SingleFieldForm
          title="Add folder"
          initialValue=""
          placeholder="Enter folder name"
          schema={ getSchemaForSingleFieldModal( __testCaseTreeItemId ) }
          onSubmit={ onAddFolderSubmit }
        />;
      }));

      return [ { name: 'Add folder', onClick }];
    }

    const onDeleteClick = () => valueRef.current.actions.removeItem({
      idToRemove: __testCaseTreeItemId,
      releaseId,
      rootItem: valueRef.current.tree,
      setValue,
    });

    /**
     * we are in "leaf select" mode. For type: 'leaf' we only want to show\
     * duplicate, rename and delete.
     */
    if( dataNode.type === 'payload' ) {
      const onDuplicateSubmit: SingleFieldFormProps[ 'onSubmit' ] = async name => (
        valueRef.current.actions.duplicateItem({
          idToDuplicate: __testCaseTreeItemId,
          newName: name,
          releaseId,
          rootItem: valueRef.current.tree,
          setValue,
          newId: uuid(),
        })
      );

      const duplicateClick = () => {
        if(maybeParent === null) return;

        setValue(prev => produce(prev, draft => {
          draft.testCasesTreeCompState.dialogContents = <SingleFieldForm
            title="Duplicate name"
            initialValue={dataNode.title}
            placeholder="Enter duplicate name"
            schema={ getSchemaForSingleFieldModal( maybeParent.id ) }
            onSubmit={ onDuplicateSubmit }
          />;
        }));
      };

      return [
        { name: 'Rename', onClick: onRenameClick },
        { name: 'Duplicate', onClick: duplicateClick },
        { name: 'Delete', onClick: onDeleteClick, className: 'error' },
      ];
    }

    /**
     * and for type: 'dir' - rename, add folder, add test case. Duplicate is\
     * excluded in phase 1 as this would require deep copying and that's a\
     * bit too big of an operation, first need some initial feedback.
     */
    const menuItemsRtrn = produce< MenuItems >([], menuItemsDraft => {
      if( dataNode.id !== '' ) {
        menuItemsDraft.push({ name: 'Rename', onClick: onRenameClick });
      }

      menuItemsDraft.push(
        {
          name: 'Add folder',
          onClick: () => openSaveAsModal({
            mode: 'folderSelect',
            initialIdToAddTo: __testCaseTreeItemId,
            initialItemToAdd: {
              id: uuid(),
              type: 'folder',
              title: '',
              children: [],
            }
          })
        },
        {
          name: 'Add test case',
          onClick: () => openSaveAsModal({
            mode: 'folderSelect',
            initialIdToAddTo: __testCaseTreeItemId,
            initialItemToAdd: {
              id: uuid(),
              title: '',
              type: 'payload',
              data: valueRef.current.actions.getDebuggerPayoad(),
              goal: valueRef.current.goalId,
            }
          })
        },
      );

      if(__testCaseTreeItemId !== '') {
        menuItemsDraft.push({ name: 'Delete', onClick: onDeleteClick, className: 'error' });
      }
    });

    return menuItemsRtrn;
  }, [mode, dataNode, setValue, __testCaseTreeItemId, valueRef, releaseId, showError, dispatch]);

  return (
    <NodeContentWrap className={ className } onClick={ onTreeNodeClick }>
      { dataNode && ( dataNode.type === 'payload' ? <TestingIcon /> : <FolderIcon /> ) }

      <NodeContentTitle variant='caption'>
        { dataNode && dataNode.title }
      </NodeContentTitle>

      <Box display='flex' gridGap='0.125rem'>
        {
          contextMenuItems.length === 0 ? null : (
            <MoreMenu className='moreMenu'>
              {/* @ts-ignore */}
              <Menu items={contextMenuItems} />
            </MoreMenu>
          )
        }

        {(() => {
          if ( dataNode === null || dataNode.type === 'payload' ) return null;

          return (
            <NodeContentIconButton onClick={ toggleExpandedHandler }>
              { expanded ? <ChevronupIcon /> : <ChevrondownIcon /> }
            </NodeContentIconButton>
          );
        })()}
      </Box>
    </NodeContentWrap>
  );
});
NodeContentRenderer.displayName = 'components/TestCasesTree/NodeContentRenderer';

//# endregion

const Wrap = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  /* @media screen and (min-height: 600px) {
    height: 250px;
  }
  @media screen and (min-height: 750px) {
    height: 300px;
  }
  @media screen and (min-height: 900px) {
    height: 350px;
  } */


  .rst__node {
    display: flex;

    >.rst__lineBlock:first-of-type {
      display: none;
    }
  }

  .rst__nodeContent {
    display: flex;
    align-items: center;
    position: relative;
    left: 0 !important;
    flex-grow: 1;
  }

  .rst__lineBlock {
    :before {
      background-color: transparent;
      border-top: 1px dashed ${ props => props.theme.palette.core.ultimateGrey };
      left: 30%;
      width: 60%;
    }

    :after {
      left: 20%;
      background-color: transparent;
      border-left: 1px dashed ${ props => props.theme.palette.core.ultimateGrey };
    }
  }

  .rst__lineHalfVerticalTop:after {
    height: calc( 50% + 1px );
  }

  .ReactVirtualized__Grid {
    ${ scrollableMixin };

    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
    }
  }
`;

const StyledDialog = styled( Dialog )`

`;

export type TestCasesTreeProps = {
  className?: string;
}
export const TestCasesTree: React.FC< TestCasesTreeProps > = React.memo( ({ className }) => {
  const {
    value: {
      testCasesTreeCompState: {
        tree,
        dialogContents,
      }
    },
    setValue
  } = useTestCasesRootCtx();

  const closeContentsDialog = React.useCallback(() => (
    setValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState.dialogContents = null;
    }))
  ), [setValue]);

  const onChange = React.useCallback< ReactSortableTreeProps< TestCaseSortTItemMeta >[ 'onChange' ] >(
    next => setValue( prev => {
      /**
       * this cast is required because sortable-tree expects children\
       * in differetn formats, array or a function, while also being \
       * able to handle undefined value. In TestCases tree that is not\
       * the case and we use more deterministic "array of test case items"\
       * so it does not really match the typing from the lib, but \
       * because we are passing "tree" into "treeData" prop of a sortable\
       * tree and "tree" is exactly the type we want - this cast is 99%\
       * trivial, i.e. we just help compiler a bit, but runtime values \
       * are correct
       */
      const nextCompTree = next[0] as typeof tree;

      return produce(prev, draft => {
        draft.testCasesTreeCompState.tree = nextCompTree;
      });
    }),
    [ setValue ]
  );

  const collapseAll = React.useCallback(() => (
    setValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState.tree = setExpandedOnAllInTestCasesSortTree({
        dataTree: draft.tree,
        sortTree: draft.testCasesTreeCompState.tree,
        expandedValue: false,
      });
    }))
  ), [setValue]);
  const expandAll = React.useCallback(() => (
    setValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState.tree = setExpandedOnAllInTestCasesSortTree({
        dataTree: draft.tree,
        sortTree: draft.testCasesTreeCompState.tree,
        expandedValue: true,
      });
    }))
  ), [setValue]);

  const nodeContentRendererFunc = React.useCallback< NodeRenderer< TestCaseSortTItemMeta > >(props => (
    <NodeContentRenderer {...props} />
  ), []);

  return (
    <Wrap className={className}>
      <StyledDialog
        open={Boolean(dialogContents)}
        onClose={closeContentsDialog}
        style={React.useMemo(() => ({ zIndex: 1301 }), [])}
        maxWidth={ false }
      >
        { dialogContents }
      </StyledDialog>

      <Box display='flex' style={React.useMemo(() => ({ gap: "0.5rem" }), [])}>
        <LinkButton size='small' onClick={ expandAll }>
          Expand all
        </LinkButton>

        <LinkButton size='small' onClick={ collapseAll }>
          Collapse all
        </LinkButton>
      </Box>

      <SortableTree< TestCaseSortTItemMeta >
        treeData={ React.useMemo(() => ([tree]), [tree])}
        onChange={ onChange }
        canDrag={ false }
        nodeContentRenderer={ nodeContentRendererFunc }
        rowHeight={28}
        scaffoldBlockPxWidth={20}
      />
    </Wrap>
  );
});
TestCasesTree.displayName = 'components/TestCasesTree/Comp';
