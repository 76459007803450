import React, { useState } from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';

import { filterValues } from '@common/util';
import { useTheme, withStyles } from '@material-ui/core';
import { ContextMenuItem, Label } from '@components';
import { Search } from './input';
import { ActionsComp } from './menuAction';
import { useAuthorise } from 'auth/permissions';


const ItemIcon = styled(ListItemIcon)`
  margin-right: 0.5rem;
  min-width: 0;
`;

const MenuContainer = styled.div`
  padding: 0 1rem;
  background-color: ${props => props.theme.palette.background.default};
  overflow: hidden;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0.5rem 0;
  padding: 0;
  margin: 0 -1rem;
  height: 100%;
  overflow-y: auto;
`;

const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

const GroupItems = styled.div`
  /* padding: 0.25rem 0; */
`;

const ItemText = styled(ListItemText)`
  margin: 0;
`;

const GroupLabel = styled(Typography)`
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  margin-bottom: 0.25rem;
`;

export const MenuItem = styled(ListItem)`
  &:hover {
    background-color: ${props => props.theme.palette.secondary.hover};
  }
`;

const ContainerItem = styled.div`
  /* margin: 0 -1rem; */
`;

export const Action = styled(ListItemSecondaryAction)`
  right: 1rem;
`;

const smallText = {
  fontSize: '0.75rem',
  fontWeight: 700,
};

const DefaultItemText = ({ name, small }) => (
  <ItemText primary={name} primaryTypographyProps={{ noWrap: true, style: small ? smallText : undefined }} />
);

export const Item = ({ name, text, icon, action, small, ...props }) => (
  <ContextMenuItem button style={small ? { padding: '0.25rem 1rem' } : {}} ContainerComponent={ContainerItem} {...props}>
    {icon && <ItemIcon>{icon}</ItemIcon>}
    {
      name.length > 16 ? (
        <Tooltip title={name}>
          {text || <DefaultItemText name={name} small={small} />}
        </Tooltip>
      ) : (
        text || <DefaultItemText name={name} small={small} />
      )
    }
    {action && <Action>{action}</Action>}
  </ContextMenuItem>
);

// ===================================================================================

const AsyncSelectItemTooltip = withStyles({
  tooltip: {
    marginLeft: 30,
  },
})(Tooltip);

const AsyncSelectItemTooltipForRelease = withStyles({
  tooltip: { marginLeft: 10 },
})(Tooltip);


/** @type { React.FC< { name: string; description?: string; } > } */
export const AsyncSelectItemTooltipTitle = (() => {
  const Wrap = styled.div`
    max-width: calc(19rem * 2 / 3);
    max-height: calc( 33.375rem * 2 / 3 );
  `;
  const Name = styled(Typography)`
    font-weight: bold;
  `;

  const comp = React.memo(({ name, description }) => {
    const truncatedDesc = React.useMemo(() => (
      (Boolean(description) === false || description.length <= 240)
        ? description
        /** 240 is a special length for current width/height of 10rem/14rem */
        : `${description.slice(0, 240)}...`
    ), [description]);


    return (
      <Wrap>
        <Name variant='caption'>{name}</Name>
        {
          description && (
            <>
              <br />
              <Typography variant='caption'>
                {truncatedDesc}
              </Typography>
            </>
          )
        }
      </Wrap>
    );
  });
  comp.displayName = 'AsyncSelectItemTooltipTitle';

  return comp;
})();

const AsyncSelectItemMenuItem = withStyles({
  container: {
    width: '100%',
    '& .MuiListItemSecondaryAction-root': {
      right: '1rem',
    },
  },
  button: {
    padding: '0.5rem 1rem',
    '&:hover': { backgroundColor: 'initial' },
    '& .MuiListItemText-root:nth-of-type(2)': {
      paddingRight: '1.4rem',
    },
  },
})(ListItem);

/**
  @type {React.FC<{
    name: string;
    icon?: JSX.Element;
    action?: JSX.Element;
    small?: boolean;
    disabled?: boolean;
    description?: string;
    isRelease?: boolean;
    releaseEnv?: string;
    isCurrent: boolean
  }>}
 */
export const AsyncSelectItem = React.memo(({
  name, icon, action, small, disabled, description, isRelease = false, releaseEnv,
  isCurrent,
}) => {
  const theme = useTheme();

  return (
    isRelease
      ? (
        <AsyncSelectItemTooltipForRelease
          title={<AsyncSelectItemTooltipTitle name={name} description={description} />}
          placement='right'
        >
          <ListItem style={{ justifyContent: 'space-between' }} selected={isCurrent}>
            <Typography variant='body1'>{name}</Typography>
            {releaseEnv && <Label color={theme.labels['Invite only']}>{releaseEnv}</Label>}
          </ListItem>
        </AsyncSelectItemTooltipForRelease>
      )
      : (
        <AsyncSelectItemMenuItem button ContainerComponent={ContainerItem} disabled={disabled} selected={isCurrent}>
          {icon && <ItemIcon>{icon}</ItemIcon>}
          <AsyncSelectItemTooltip
            title={<AsyncSelectItemTooltipTitle name={name} description={description} />}
            placement='right'
          >
            <ItemText primary={name} primaryTypographyProps={{ noWrap: true, style: small ? smallText : undefined }} />
          </AsyncSelectItemTooltip>
          {action && <Action>{action}</Action>}
        </AsyncSelectItemMenuItem>
      )
  );
});
AsyncSelectItem.displayName = 'AsyncSelectItem';

/**
  @type {React.FC<{
    items: { name: string; node?: JSX.Element; onClick?: () => void; className?: string; role?: string | string[]; [name: string]: any }[];
    actions?: { name: string; onClick: () => void }[];
    search?: string;
    className?: string;
    groupBy?: { name: string; filter: (item: any) => boolean }[];
    width?: number | string;
    height?: number | string;
    small?: boolean;
    onOptionClick?: (item: { name: string; node?: JSX.Element; onClick: () => void }) => void;
  }>}
 */
export const Menu = ({ items, actions = undefined, search = undefined, className = undefined, groupBy = null, width = 'unset', height = 'unset', small = false, onOptionClick = undefined }) => {
  const [filter, setFilter] = useState('');
  const authorise = useAuthorise();

  let content = items.filter(item => item.role ? authorise(item.role) : true);

  const renderItem = React.useCallback(({ role, ...item }) => (
    item.node || (
      <Item
        key={item.name}
        small={small}
        onClick={onOptionClick ? () => onOptionClick(item) : item.onClick}
        {...item}
      />
    )
  ), [small, onOptionClick]);

  // if after role check we have no options, simply return null
  if(content.length === 0) {
    return null;
  }

  // we have items, so we can apply a filter
  content = content.filter(item => item.node || filterValues(filter, item.name));

  if (groupBy) {
    // [{ name, filterFn }]
    content = groupBy.map(g => ({
      name: g.name,
      items: content.filter(g.filter),
    }));
  }

  const size = {
    width: typeof width === 'string' ? width : `${width}px`,
    maxWidth: typeof width === 'string' ? width : `${width}px`,
    height: typeof height === 'string' ? height : `${height}px`,
  };

  const menuHeight = {
    height: `calc(100% - ${search ? '4rem' : '0px'} - ${actions ? '2.75rem - 1px' : '0px'})`,
    padding: search ? '0 0 0.5rem 0' : small ? '0.75rem 0' : '1rem 0',
  };

  return (
    <MenuContainer style={size} className={className}>
      {search && (
        <Search
          style={{ padding: '1rem 0 0.5rem 0' }}
          fullWidth
          placeholder={search}
          value={filter}
          setValue={setFilter}
        />
      )}

      <MenuItems style={menuHeight} className='menu-items'>
        {
          groupBy
            ? content.flatMap((g, i) => {
              if (g.items.length > 0) {
                return (
                  <Group key={`${g.name}-${i}`}>
                    <GroupLabel variant='h6'>{g.name}</GroupLabel>
                    <GroupItems>
                      {g.items.map(renderItem)}
                    </GroupItems>
                  </Group>
                );
              }

              return null;
            })
            : content.map(renderItem)
        }
      </MenuItems>

      <ActionsComp actions={actions} />
    </MenuContainer>
  );
};
