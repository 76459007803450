import React from "react";
import { css } from "styled-components";

import { COMMANDS, type Command } from "@packages/compiler";
import camelCase from "lodash/camelCase";
import sortBy from "lodash/sortBy";
import { Typography } from "@material-ui/core";
import { MentionCombobox } from "./MentionCombobox";
import { createMentionPluginFactory, getMentionOnSelectItem, Item } from "./common";

const convertFunctionName = (value: string) => {
  const text = camelCase(value);
  return text[0].toUpperCase() + text.slice(1);
};

const convertFunction = (command: Command) => {
  const { id, arguments: args } = command;
  return `${convertFunctionName(id)}(${args
    .reduce((acc, arg) => {
      if (arg.required) {
        acc.push(arg.name);
      }
      return acc;
    }, [] as string[])
    .join(", ")})`;
};

const FUNCTION_TRIGGER_CHAR = "/";
const functionData = COMMANDS.map((f) => ({ text: convertFunctionName(f.id), data: f, value: convertFunction(f) }));
const bold = { fontWeight: "bold" };
const italic = { fontStyle: "italic" };

type FunctionDeclarationProps = {
  data: Command;
};

export const FunctionDeclaration = ({ data }: FunctionDeclarationProps) => {
  const { id, arguments: args } = data;
  console.log(args);
  return (
    <>
      <span>{convertFunctionName(id)}</span>(
      {args.map((arg, i) => {
        const { name, required, variadic } = arg;
        const variadicString = variadic ? "..." : "";
        const optionalString = required ? "" : "?";

        return (
          <React.Fragment key={i}>
            {i > 0 && ", "}
            <span style={{ opacity: required ? 1 : 0.5 }}>{`${name}${optionalString}${variadicString}`}</span>
          </React.Fragment>
        );
      })}
      )
    </>
  );
};

export interface FunctionDetailsProps {
  data: Command;
}

export const Details = (props: FunctionDetailsProps) => {
  const { data } = props;
  if (!data) {
    return "Missing function data";
  }

  const { displayName, description } = data;

  return (
    <>
      <Typography
        variant="caption"
        style={bold}
      >
        {displayName}
      </Typography>
      <Typography
        variant="caption"
        style={italic}
      >
        <FunctionDeclaration data={data} />
      </Typography>
      {description ? (
        <Typography
          variant="caption"
          style={italic}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}
    </>
  );
};

export const FunctionCombobox = () => {
  return (
    // @ts-ignore
    <MentionCombobox
      pluginKey={FUNCTION_TRIGGER_CHAR}
      items={sortBy(functionData, "text")}
      onRenderItem={Item}
      onRenderDetail={Details}
      onSelectItem={getMentionOnSelectItem({ key: FUNCTION_TRIGGER_CHAR })}
      styles={{
        root: [
          css`
            border-radius: 0.5rem;
            box-shadow: ${(p) => p.theme?.palette.body.boxShadow};
            width: 32rem;
            overflow: hidden;
          `,
        ],
      }}
    />
  );
};

export const createFunctionsPlugin = createMentionPluginFactory({
  key: "functions",
  trigger: FUNCTION_TRIGGER_CHAR,
});