import styled from "styled-components";

// status circle, one inner, one outer, color is a prop
// using 2 pseudo elements so the parent can define the size
export const StatusCircle = styled.div`
  position: relative;
  width: 1.25rem; // 20px
  height: 1.25rem; // 20px

  &::before {
    content: "";
    display: block;
    width: 1.25rem; // 20px
    height: 1.25rem; // 20px
    border-radius: 50%;
    position: absolute;
    background-color: ${p => p.color};
    opacity: 0.3;
  }

  &::after {
    content: "";
    display: block;
    width: 0.625rem; // 10px
    height: 0.625rem; // 10px
    border-radius: 50%;
    position: absolute;
    background-color: ${p => p.color};
    top: 25%;
    left: 25%;
  }
`;