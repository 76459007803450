import moment from "moment";
import { useTranslation } from "react-i18next";
import MaterialTable from "@material-table/core"
import { Chip, Typography, TypographyProps } from "@material-ui/core";
import { useScope } from "redux/scope";
import { sessionService } from "services";
import { Label, Tooltip } from "@components";
import styled from "styled-components";
import { useCurrentRelease } from "@common";
import { find } from "lodash";
import AssistantIcon from '@material-ui/icons/Assistant';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
const TableContainer = styled.div`
  /* height: calc(100% - 6rem); */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  border-top: 1px solid #e5e5e5;

  >div {
    flex: 1;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .MuiToolbar-root {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${p => p.theme.palette.background.default};
    padding-left: 2rem;
    padding-right: 1rem;

    [class*="MTableToolbar-title"]>.MuiTypography-root {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      text-transform: none;
    }
  }

  .MuiTableRow-root {
    border-top: 1px solid #e5e5e5;
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      padding: 0.5rem 0;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 200%;
    }
  }

  .MuiTableBody-root .MuiTableCell-root:not(.MuiTableCell-paddingNone) {
    padding: 0.75rem 0;
  }

  .MuiTableCell-root {
    padding: 0;
    border: none;
  }

  .MuiTableCell-root .MuiCollapse-root .MuiPaper-root {
    box-shadow: none;
    background-color: ${p => p.theme.palette.background.light};
    border-radius: 0;
    padding: 1rem;

    table {
      border-collapse: separate; // required for border-radius to work
      border-spacing: 0 0.25rem; // space between rows
    }

    .MuiTableRow-root {
      border-top: none;
      > .MuiTableCell-root:first-child {
        padding-left: 1rem !important;
      }
    }

    .MuiTableHead-root > .MuiTableRow-root {
      > .MuiTableCell-head {
        padding: 0;
        background: none;
      }
    }

    .MuiTableBody-root > .MuiTableRow-root {
      background-color: ${p => p.theme.palette.background.default};

      > td.MuiTableCell-body {
        padding: 0.5rem 0;
        border: 1px solid ${p => p.theme.palette.divider};
        border-right: none;
        border-left: none;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-left: 1px solid ${p => p.theme.palette.divider};
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-right: 1px solid ${p => p.theme.palette.divider};
        }
      }
    }
  }
`;

const formatDate = (date: string) => {
  // use moment to format date as DD MMM, YYYY
  return moment(date).format("DD MMM, YYYY");
};

const DateCell = (field: string) => (data: any) => {
  return formatDate(data[field]);
};

const LabelCell = (field: string, color: string | ((val: string) => string)) => (data: any) => {
  const { t } = useTranslation();
  const label = data[field];
  return (
  <Label color={typeof color === "string" ? color : color(label)}>
    {t(`tasks.status.${label ?? "in-progress"}`)}
  </Label>
  );
};

const ShortField = ({ value, textWidth, ...props }: { value: string, textWidth?: number } & TypographyProps) => (
  <Tooltip title={value} placement="right">
    <Typography {...props}>
      {`${value.slice(0, textWidth || 8)}...`}
    </Typography>
  </Tooltip>
);

const InteractionTable = ({ sessionId }: { sessionId: string; }) => {
  const { t } = useTranslation();
  const release = useCurrentRelease();

  const { data, isError, isLoading } = sessionService.useGetOne(sessionId, {});
  if (!release) return;
  if (isError) {
    return <div>Error...</div>
  }
  const rule_graph = release.rule_graph;

  const interactions = data?.interactions || [];
  return (
    <MaterialTable
      columns={[
        {
          title: 'Interaction',
          field: 'mode',
          render: (data) => {
            switch (data.mode) {
              case "interview":
                return "Interview";
              case "api":
                return "API";
              case 'generative':
                return 'Generative Chat';
              default:
                return "Interaction";
            }
          },
        },
        { title: 'Last Updated', field: 'created', render: (data) => {
          return (
            <Tooltip title={`Created: ${data.created}`}>
              <span>{moment(data.lastModified).fromNow()}</span>
            </Tooltip>
          )
        }, minWidth: 130 },
        // { title: 'Last updated', field: 'lastModified' },
        { title: 'Type', field: 'mode', render: (data) => {
          let icon;
          switch (data.mode) {
            case 'interview':
              return (

                <Tooltip title="Interview">
                  <Chip icon={<ListAltIcon/>} label={data.interviewName} />
                </Tooltip>
              );
              // @ts-ignore
            case 'generative':
              icon = <AssistantIcon fontSize="small"/>;
            default:
              if (!icon) icon = <SettingsIcon fontSize="small" />;
              let node = find(rule_graph.nodes, { v: data.goal});
              if (node) {
                let desc = node.value.description;
                if (desc.length > 32) desc = desc.substr(0, 32) + '...';
                return (
                  <Tooltip title={`Type: ${data.mode} | Goal: ${node.value.description}`}>
                    <Chip icon={icon} label={desc} />
                  </Tooltip>
                )
              }
              return data.mode;
          }
        }},
        { title: 'Status', field: 'status', render: LabelCell('status', 'purpleSlate'), minWidth: 130 },
      ]}
      data={interactions}
      title={t('monitor.interactions')}
      isLoading={isLoading}
      options={{
        toolbar: false,
        search: false,
        paging: false,
      }}
    />
  )

};

export const SessionTable = () => {
  const { workspace, project, release } = useScope();
  const { data: sessions, total, query } = sessionService.useResources({
    filter: {
      // workspace,
      model: project,
      release,
      sort: { field: "created", order: "desc" } as const,
    }
  });
  const fullRelease = useCurrentRelease();
  if (!fullRelease) return;
  const rule_graph = fullRelease.rule_graph;
  // if (query.isLoading) {
  //   return <div>Loading...</div>
  // }

  return (
    <TableContainer>
      <MaterialTable
        columns={[
          { title: 'Session', field: 'id', render: (data) => <ShortField value={data.id} />, minWidth: 100 },
          //{ title: 'Created', field: 'created', render: DateCell('created'), minWidth: 130 },
          { title: 'Last updated', field: 'lastModified', render: (data) => {
            //DateCell('lastModified')
            return (
              <Tooltip title={`Created: ${data.created}`}>
                <span>{moment(data.lastModified).fromNow()}</span>
              </Tooltip>
            )
          }, minWidth: 130 },
          { title: 'Interactions', field: 'lastInteractionName', render: (data) => {

            return (
              <>
                <Tooltip title="Total Interactions">
                  <Chip label={data.totalInteractions} />
                </Tooltip>
                &nbsp;
                <Tooltip title="Total Active Interactions">
                  <Chip color="primary" label={data.totalActiveInteractions} />
                </Tooltip>

              </>
            )
          } },
          { title: 'Goal', field: 'id', render: (data) => {
            if (!rule_graph) return '';
            let node = find(rule_graph.nodes, { v: data.goal});
            if (node) return (
              <ShortField value={node.value.description} textWidth={32} />
            );
            return '';
          }, minWidth: 300},
          { title: 'Status', field: 'status', render: LabelCell('status', 'purpleSlate'), minWidth: 130 },
        ]}
        data={sessions}
        title="Sessions"
        isLoading={query.isLoading}
        detailPanel={data => <InteractionTable sessionId={data.rowData.id} />}
        onRowClick={(event, rowData, togglePanel) => togglePanel?.()}
        options={{
          pageSize: 10,
          searchFieldVariant: "outlined",
          detailPanelType: "single",
        }}
      />
    </TableContainer>
  )
}
