// @ts-nocheck dont check this file, we need to rework the styling
import { useMemo } from "react";
import { AreaChart, Area } from "recharts";
import { Box, Card, CardProps, Theme, Typography } from "@material-ui/core";
import { Flex, Stack } from "@components";
import Skeleton from "@material-ui/lab/Skeleton";
import { css } from "@emotion/react";
import { generate } from "generate-password-browser";
import { ArrowDownIcon } from "@radix-ui/react-icons";
import { system } from "theme";

const styles = {
  card: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    /* min-width: 10rem; */
    box-shadow: none;
    color: ${theme.palette.secondary.main};
    border: 1px solid ${theme.palette.background.border};
    padding: 0.75rem 1.5rem;
  `,
  common: (theme: Theme) => css`
    --svg-color: ${theme.palette.secondary.main};

    &[data-critical="true"] {
      --svg-color: ${theme.palette.error.main};
    }

    svg.recharts-surface {
      color: var(--svg-color);
      path {
        /* stroke: var(--svg-color); */
        /* stroke-width: 3; */
      }
    }

    &[data-small="true"] {
      svg.recharts-surface {
        padding-left: 0.25rem;
      }
    }

    &[data-critical="true"] {
      /* color: ${theme.palette.error.contrastText}; */
      background-color: ${theme.palette.error.light};
      // needs border to match the same height
      /* border: 1px solid ${theme.palette.background.border}; */

      &[data-small="true"] {
        /* color: ${theme.palette.error.main}; */
        background-color: unset;
        border: none;
      }
    }
  `,
};

export const AnalyticsSkeletonCard = ({ small = true }: { small?: boolean }) =>
  small ? (
    <Box>
      <Stack flexDirection="row" alignItems="center" gridGap={0.5}>
        <Skeleton variant="text" width="1.5rem" height="1rem" />
        <Skeleton variant="text" width="2rem" height="1rem" />
        <Skeleton variant="rect" width="1.5rem" height="1rem" />
      </Stack>
    </Box>
  ) : (
    <Card css={styles.card}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width="4rem" height="calc(2rem * 1.2)" />
        <Skeleton variant="rect" width="3rem" height="2rem" />
      </Box>
      <Skeleton variant="text" width="6rem" height="1.5rem" />
    </Card>
  );

type BasicLineProps = {
  data: number[];
  width: number;
  height: number;
};

const BasicLineChart = ({ data, width, height }: BasicLineProps) => {
  const maxValue = Math.max(...data, 1);
  const xScaleFactor = width / data.length; // scale factor for the x-axis (1-hour intervals)
  const yScaleFactor = height / maxValue; // scale factor for the y-axis (values 0-50)

  const points = useMemo(() => data.map((value, i) => {
    const x = i * xScaleFactor; // + px
    const y = (value * yScaleFactor); // + py
    return { x, y };
  }), [data, xScaleFactor, yScaleFactor, height]);

  const id = generate({ length: 8, numbers: true });

  return (
    <AreaChart data={points} width={width} height={height} margin={{ bottom: 0, top: 0, left: 0, right: 0 }}>
      <defs>
        <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="var(--svg-color)" stopOpacity={0.3} />
          <stop offset="100%" stopColor="var(--svg-color)" stopOpacity={0.1} />
        </linearGradient>
      </defs>
      {/* <Line type="monotone" dataKey="y" dot={false} /> */}
      <Area type="monotone" dataKey="y" stroke="#0a0a0a" strokeWidth={3} fillOpacity={1} fill={`url(#${id})`} />
    </AreaChart>
  );
};

type AnalyticCardProps = CardProps & {
  title: string,
  value: string | number,
  data: number[],
  critical?: boolean,
  small?: boolean,
  // css?: CssProp;
};

export const AnalyticCard = ({ title, value, data, critical = false, small = false, ...props }: AnalyticCardProps) => {
  const rootStyles = props.css ? [styles.common, props.css] : [styles.common];

  if (small) {
    return (
      <Stack {...props} css={rootStyles} data-critical={critical} data-small={small}>
        <Stack flexDirection="row" alignItems="center" gridGap={0.5}>
          <Typography variant="h6">{value}</Typography>
          <Typography variant="caption">{title}</Typography>
          <BasicLineChart data={data} width={24} height={12} />
        </Stack>
      </Stack>
    );
  }

  rootStyles.push(styles.card);

  return (
    <Card {...props} css={rootStyles} data-critical={critical}>
      <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      <Flex justifyContent="space-between" alignItems="center" gridGap={1}>
        <Stack gridGap="0.25rem">
          <Typography variant="h2">{value}</Typography>
          <Flex alignItems="center" gridGap="0.5rem">
            <Typography variant="caption" style={{ display: "flex", alignItems: "center", fontWeight: "bold", color: critical ? system.trafficGreen : system.trafficRed }}>
              <ArrowDownIcon />
              <span>-3%</span>
            </Typography>
            <Typography variant="caption">+1.01% this month</Typography>
          </Flex>
        </Stack>
        <BasicLineChart data={data} width={16 * 8} height={16 * 4} />
      </Flex>
    </Card>
  );
};
